import "./style.scss";

import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import Moment from "react-moment";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";

import SVG from "../components/Svg";
import useEvent from "../hooks/useEvent";
import MenuLinks from "./SidebarConfig";
import SidebarItem from "./SidebarItem";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootDesktopStyle = styled("div")(() => ({
  flexShrink: 0,
  height: "calc(100vh - 92px)",
  width: DRAWER_WIDTH,
  padding: "30px 0 20px 0",
  overflowX: "hidden",
  overflowY: "auto",
}));

const RootMobileStyle = styled("div")(() => ({
  width: DRAWER_WIDTH,
  padding: "40px 0",
}));

const EventStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0px 10px",
  margin: "0 16px",
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: "#26282D",
  width: "245px",
  height: "65px",
  overflow: "hidden",
}));

const DocStyle = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor: "#2A2D3A",

  "& > .__lamp": {
    flex: "1",
    position: "relative",
    "& > svg": {
      position: "absolute",
      top: "-5px",
      right: "-5px",
      width: "65px",
      height: "auto",
    },
  },
}));

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items,
        })}
      </SidebarItem>,
    ];
  }
  return [
    ...array,
    <SidebarItem
      key={key}
      level={level}
      href={item.href}
      icon={item.icon}
      info={item.info}
      title={item.title}
    />,
  ];
}

function renderSidebarItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar }) => {
  const { pathname } = useLocation();
  const { data } = useEvent();
  const LIVE_URL = process.env.REACT_APP_EVEY_URL;
  const previewLink = `${LIVE_URL}/events/${data?.organization?.slug}/${data.slug}`;

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <div className="SidebarContent">
      <EventStyle>
        <Box className="__date">
          <p className="__day">
            <Moment format="D">{data?.startDate || new Date()}</Moment>
          </p>
          <p className="__month">
            <Moment format="MMMM">{data?.startDate || new Date()}</Moment>
          </p>
        </Box>
        <Box className="__details" sx={{ ml: 2 }}>
          <Box className="__details__title">{data?.title || "New Event"}</Box>
          <Box
            className="__details__preview"
            onClick={() => {
              window.open(previewLink, "_blank");
            }}
          >
            Preview event
          </Box>
        </Box>
      </EventStyle>

      <div style={{ flex: "1" }}>
        {MenuLinks.map((list) => (
          <List
            disablePadding
            key={list.subheader}
            subheader={
              <ListSubheader
                disableSticky
                disableGutters
                sx={{
                  mt: "20px",
                  mb: "10px",
                  ml: "30px",
                  color: "#454545",
                  fontFamily: "Poppins , sans-serif",
                  fontStyle: "normal",
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: "700",
                  letterSpacing: "1.2px",
                }}
              >
                {list.subheader}
              </ListSubheader>
            }
          >
            {renderSidebarItems({
              items: list.items,
              pathname,
            })}
          </List>
        ))}

        <Box sx={{ px: 2.5, pb: 3, mt: "20px" }}>
          <DocStyle>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "Poppins , sans-serif",
                }}
              >
                Need <br />
                assistance?
              </Typography>
              <Button
                endIcon={<SVG src="/static/icons/assis_arrow.svg" />}
                style={{
                  padding: "0",
                  width: "120px",
                  whiteSpace: "nowrap",
                  fontSize: "11px",
                  fontWeight: "600",
                }}
                onClick={() => window.open("https://eveytech.com", "__blank")}
              >
                Explore help center
              </Button>
            </Box>
            <Box className="__lamp">
              <SVG src="/static/icons/assis_lamp.svg" />
            </Box>
          </DocStyle>
        </Box>
      </div>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              border: "none",
            },
          }}
        >
          <RootMobileStyle>{renderContent}</RootMobileStyle>
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <RootDesktopStyle
          className="scrollbar __hidden"
          style={{ boxShadow: "inset -1px 0px 0px rgba(145, 158, 171, 0.24)" }}
        >
          {renderContent}
        </RootDesktopStyle>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
