import * as yup from "yup";

import { dateToID } from "../../utils/dates";

export const TABLE_HEAD = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Slot name",
  },
  {
    id: "startTime",
    numeric: false,
    disablePadding: true,
    label: "Start time",
  },
  {
    id: "duration",
    numeric: false,
    disablePadding: true,
    label: "Duration",
  },
  {
    id: "speakerId",
    numeric: false,
    disablePadding: true,
    label: "Speaker",
  },
];

export const getTicketData = (t) => ({
  ...t,
  title: t.title || "",
  isFree: t.isFree || false,
  isActive: t.isActive || false,
  price: t.price || "",
  currency: t.currency || "one",
  // ...
  conditionKey: t.conditionKey || "",
  conditionValue: t.conditionValue || "",
  // ...
  textColor: t.textColor || "#000000",
  backgroundColor: t.backgroundColor || "#ffffff",
  // ...
  backgroundImage: t.backgroundImage || null,
  bannerImage: t.bannerImage || null,
  // ...
  heading: t.heading || "",
  subHeading1: t.subHeading1 || "",
  subHeading2: t.subHeading2 || "",
  subHeading3: t.subHeading3 || "",
  paragraph: t.paragraph || "",
  // ...
  text: t.text || "",
  subText1: t.subText1 || "",
  subText2: t.subText2 || "",
  subText3: t.subText3 || "",
});

export const validationSchema = yup
  .object({
    title: yup.string().required("Ticket name is required"),
    isFree: yup.boolean(),
    isActive: yup.boolean(),
    price: yup.string(),
    currency: yup.string(),
    // ...
    conditionKey: yup.string().required(),
    conditionValue: yup.string().required(),
    // ...
    textColor: yup.string().required(),
    backgroundColor: yup.string().required(),
    // ...
    backgroundImage: yup.object().required().nullable(),
    bannerImage: yup.object().required().nullable(),
    // ...
    heading: yup.string().notRequired(),
    subHeading1: yup.string().notRequired(),
    subHeading2: yup.string().notRequired(),
    subHeading3: yup.string().notRequired(),
    paragraph: yup.string().notRequired(),
    // ...
    text: yup.string().notRequired(),
    subText1: yup.string().notRequired(),
    subText2: yup.string().notRequired(),
    subText3: yup.string().notRequired(),
  })
  .default({
    title: "",
    isFree: false,
    isActive: false,
    price: "",
    currency: "one",
    // ...
    conditionKey: "",
    conditionValue: "",
    // ...
    textColor: "#000000",
    backgroundColor: "#ffffff",
    // ...
    backgroundImage: null,
    bannerImage: null,
    // ...
    heading: "",
    subHeading1: "",
    subHeading2: "",
    subHeading3: "",
    paragraph: "",
    // ...
    text: "",
    subText1: "",
    subText2: "",
    subText3: "",
  });
