import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const getSponsorData = (s) => ({
  name: s.name,
  slogan: s.slogan,
  description: s.description,
  email: s.email,
  phone: s.phone,
  website: s.website,
  facebook: s.facebook,
  linkedin: s.linkedin,
  instagram: s.instagram,
  // ...
  settings: s?.settings || {},
  // ...
  card: s?.card,
  cover: s?.cover,
  // blues - file at the bottom
});

const getSponsorLinkData = (s) => ({
  level: s?.level,
  type: s?.type,
  status: s?.status || "pending",
});

const ATOM = atom({ key: "SPONSORS", default: [] });

export default function useSponsors() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAllSponsors = async () => {
    try {
      const event = await HTTP.GET(`/organizations/events/${THE_ID}`);
      const raw = event?.sponsors || [];
      const sponsors = raw
        .filter((s) => !!s?.id && !!s?.sponsor?.id)
        .map((s) => ({
          ...s.sponsor,
          ...s,
          sponsor: s.sponsor.id,
        }));
      // ...
      setAtom(sponsors);
    } catch (err) {
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  };
  // ...
  const onFetch = useRecoilCallback(() => async (email) => {
    try {
      setIsLoading(true);
      setHasError(false);
      // ...
      let sponsors = await HTTP.GET(`/sponsors/?email=${email}`);
      // ...
      setIsLoading(false);
      setHasError(false);
      // ...
      return sponsors?.[0] || null;
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return null;
    }
  });
  // ...
  const onUpdate = useRecoilCallback(() => async (raw) => {
    setIsLoading(true);
    setHasError(false);

    const SPONSOR_ID = raw.sponsor;
    const LINKEDD_ID = raw.id;

    // # Add/Edit Sponsor
    let sponsorData = getSponsorData(raw);
    let NEW_SPONSOR = {};
    if (!SPONSOR_ID) {
      NEW_SPONSOR = await HTTP.POST("/sponsors", sponsorData);
    }

    // # Link Sponsor
    let sponsorLinkData = getSponsorLinkData(raw);
    sponsorLinkData.sponsor = SPONSOR_ID || NEW_SPONSOR?.id;

    try {
      if (LINKEDD_ID) {
        await HTTP.POST(`/organizations/events/${THE_ID}/editSponsor`, {
          id: LINKEDD_ID,
          ...sponsorLinkData,
        });
      } else {
        await HTTP.POST(
          `/organizations/events/${THE_ID}/addSponsor`,
          sponsorLinkData
        );
      }
      // ...
      enqueueSnackbar(LINKEDD_ID ? "Sponsor updated" : "Sponsor added", {
        variant: "success",
      });
      // ...
      getAllSponsors();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  });
  const onRemove = useRecoilCallback(() => async (sponsorIds) => {
    setIsLoading(true);
    setHasError(false);

    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/detatchSponsors`, {
        ids: [sponsorIds],
      });
      // ...
      enqueueSnackbar("Sponsor deleted", { variant: "success" });
      // ...
      getAllSponsors();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAllSponsors();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onFetch,
    onUpdate,
    onRemove,
  };
}
