import { Box, Card, Container } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import EventButton from "../../components/EventButton";
import EventEmptyView from "../../components/EventEmptyView";
import EventEntityTable from "../../components/EventEntityTable";
import EventHeader from "../../components/EventHeader";
import EventSectionTitle from "../../components/EventSectionTitle";
import ModalValidation from "../../components/ModalValidate";
// ...
import Page from "../../components/Page";
import useBadges from "../../hooks/useBadges";
import { TABLE_HEAD } from "./config";

const EventBadge = () => {
  const isMobile = useMediaQuery("(max-width:850px)");
  const [delModal, setDelModal] = useState({
    open: false,
    id: null,
    label: "",
  });
  // ...
  const history = useHistory();
  const { isLoading, data: badges, onCreate, onDelete } = useBadges();
  // ...
  const onAddBadge = async () => {
    const { badgeId, error } = await onCreate();
    // ...
    if (error) return;
    history.push(`/manage-badges/${badgeId}`);
  };
  const onEditBadge = (data) => {
    history.push(`/manage-badges/${data.id}`);
  };
  const onDeleteBadge = async (id) => {
    onDelete(id);
    // ...
    setDelModal({ open: false, id: null, label: "" });
  };
  // ...
  return (
    <Page title="Badging">
      <Container>
        <Box>
          <EventSectionTitle
            title="Badging"
            subTitle="Create and manage event badges"
            src="/static/setup/title/badges.svg"
          />

          {badges.length > 0 && (
            <EventSectionTitle
              title="View badges list"
              subTitle="Review and manage badges"
              sx={{ marginBottom: "30px !important" }}
            >
              <EventButton isLoading={isLoading} handler={() => onAddBadge()}>
                {isMobile && "New"}
                {!isMobile && "New badge"}
              </EventButton>
            </EventSectionTitle>
          )}

          {badges.length === 0 && (
            <EventEmptyView
              src="/static/setup/empty/badges.svg"
              title="Create your first badge"
              desc="Create and manage event badges "
              btnLabel="Create new badge"
              handler={onAddBadge}
              isLoading={isLoading}
            />
          )}

          {badges.length > 0 && (
            <Box>
              <Card>
                <EventEntityTable
                  head={TABLE_HEAD}
                  data={badges}
                  onEditHandler={(data) => onEditBadge(data)}
                  onDeleteHandler={(data) => {
                    setDelModal({
                      open: true,
                      id: data.id,
                      label: data?.title,
                    });
                  }}
                />
              </Card>
            </Box>
          )}
        </Box>
      </Container>

      <ModalValidation
        open={delModal.open}
        title="Delete sponsor"
        isLoading={isLoading}
        // ...
        yes={{
          label: "delete",
          handler: () => onDeleteBadge(delModal.id),
        }}
        no={{
          label: "cancel",
          handler: () => setDelModal({ open: false, id: null, label: "" }),
        }}
      >
        Are you sure to delete {delModal?.label || "badge"}?
      </ModalValidation>
    </Page>
  );
};

export default EventBadge;
