import "./style.scss";

import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withFormik } from "formik";
import React, { useEffect } from "react";

import useFile from "../../hooks/useFile";
import { EntryCheckbox, EntryText } from "../Entry";
import EventButton from "../EventButton";
import EventForm from "../EventForm";
import LoadingDots from "../LoadingDots";
import { validationSchema } from "./entries";
const iniValues = validationSchema.cast();

const AddSpeaker = ({
  idx,
  data = {},
  onAddSpeaker,
  onEditSpeaker,
  // ...
  isLoading: isLA,
  // ...
  ...props
}) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { isLoading: isLF, onUpload } = useFile();
  const isEdit = data?.id;
  // ...
  const isLoading = isLA || isLF;
  const imgSrc = props.values?.image?.url || null;
  // ...
  const onUploadFile = async (e) => {
    const files = e.target.files;
    if (files?.length === 0) return;
    // ...
    const data = await onUpload(files);
    props.setFieldValue("image", data[0]);
  };
  // ...
  useEffect(() => {
    props.resetForm({ values: { ...iniValues, ...data } });
  }, [data]);
  // ...
  return (
    <>
      <Grid container sx={{ margin: "0 0 40px 0" }}>
        <Grid item xs={12} md={4}>
          <div className="speaker__photo">
            {imgSrc && (
              <div className="speaker__photo__img">
                <input type="file" onChange={onUploadFile} />
                <img src={imgSrc} />
                {isLF && (
                  <div className="speaker__photo__img__load">
                    <LoadingDots />
                  </div>
                )}
              </div>
            )}
            {!imgSrc && (
              <div className="speaker__photo__inner">
                <input type="file" onChange={onUploadFile} />
                <div className="__icon">
                  {!isLF && (
                    <img
                      src="/static/icons/upload.svg"
                      sx={{ width: 16, height: 16 }}
                    />
                  )}
                </div>
                <div className="__label">
                  {isLF ? <LoadingDots /> : "Upload cover photo"}
                </div>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={0} md={1} />
        <EventForm
          xs={12}
          md={7}
          spacing={1}
          sx={{ padding: "0", marginTop: "-20px" }}
        >
          <EntryText
            topLabel="First name"
            placeholder="Speaker first name"
            name="firstName"
            md={12}
          />
          <EntryText
            topLabel="Last name"
            placeholder="Speaker last name"
            name="lastName"
            md={12}
          />
        </EventForm>
      </Grid>
      <EventForm>
        <EntryText
          label="Biography"
          name="description"
          multiline
          minRows={2}
          maxRows={2}
          md={12}
        />
        <EntryText
          topLabel="Position"
          placeholder="CEO, Manager, artist, etc."
          name="position"
        />
        <EntryText
          topLabel="Company"
          placeholder="Company , organization"
          name="company"
        />
        <EntryText
          topLabel="Email"
          placeholder="speaker@domain.com"
          type="email"
          name="email"
        />
        <EntryText
          // type="number"
          topLabel="Phone number"
          plaeholder="+216 22 000 000"
          name="phone"
        />
        <EntryText
          topLabel="Linkedin"
          placeholder="LinkedIn profile"
          name="linkedin"
        />
        <EntryCheckbox
          name="isModerator"
          label="Make this speaker as agenda modarator"
          labelPlacement="end"
          sx={{ marginTop: isMobile ? "0" : "30px" }}
        />
        <EntryCheckbox
          name="sendEmailNotification"
          label="Send email invitation to the speaker"
          labelPlacement="end"
        />
      </EventForm>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "50px 0 0 0",
        }}
      >
        <EventButton
          isLoading={isLoading}
          handler={() =>
            isEdit
              ? onEditSpeaker(props.values, idx)
              : onAddSpeaker(props.values)
          }
        >
          Save Speaker
        </EventButton>
      </div>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(AddSpeaker);
