import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

function getFileData(file) {
  return new Promise((resolve /*, reject*/) => {
    const name = file?.name?.replace(/ /g, "");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // ...
    reader.onload = () =>
      resolve({
        fileInfo: {
          name,
          alternativeText: "image",
          caption: "image",
        },
        base64: reader.result,
      });
    // reader.onerror = (error) => reject(error);
  });
}

const ATOM = atom({ key: "FILES", default: {} });

export default function useFile() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const onUpload = useRecoilCallback(() => async (raw) => {
    setIsLoading(true);
    setHasError(false);
    // ...
    const files = [];
    for (const file of Array.from(raw)) {
      const data = await getFileData(file);
      files.push(data);
    }
    // ...
    const data = await HTTP.POST(
      `/organizations/events/${THE_ID}/uploadFile`,
      files[0]
    );
    // ...
    setAtom(data);
    return data;
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onUpload,
  };
}
