export default function useSettings() {
  return {
    // Mode
    themeMode: "light",
    toggleMode: null,
    selectMode: null,
    // Direction
    themeDirection: "ltr",
    selectDirection: null,
  };
}
