import { Box, Button, Grid, TextField } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import cx from "classnames";
import { useField } from "formik";
import React, { useRef, useState } from "react";

import LoadingDots from "../../LoadingDots";

const EntryFile = ({
  required,
  name,
  label,
  placeholder,
  // ...
  accept = ".jpg,.png,.jpeg,.svg",
  multiple,
  // ...
  preview,
  previewStyle = {},
  // ...
  xs = 12,
  md = 6,
  sx = {},
  // ...
  compact,
  medium,
  // ...
  onUpload,
  // ...
  isLoading: isLO,
}) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const [isLI, setIsLoading] = useState(false);
  const isLoading = isLI || isLO;
  // ...
  const [field] = useField({ name });
  const inpRef = useRef();
  // ...
  const raw = field?.value;
  const value = Array.isArray(raw) ? raw?.[0] : raw;
  const fName = value?.name;
  const fUrl = value?.url;
  const hasValue = !!fName;
  // ...
  const onChangeHandler = async (e) => {
    const inp = e.target;
    setIsLoading(true);

    // # Get Files
    const files = inp.files || null;
    if (!files || files.length === 0) {
      setIsLoading(false);
      return;
    }

    // # Upload
    await onUpload(name, files);

    // # Clear input
    inp.value = "";
    // ...
    setIsLoading(false);
  };
  // ...
  let btnLabel = "Upload files";
  if (medium) btnLabel = "Upload";
  if (compact || isMobile) btnLabel = <AttachFileIcon />;
  if (isLoading) btnLabel = <LoadingDots type="small" color="white" />;
  // ...
  return (
    <Grid item xs={xs} md={md}>
      <Box className="Entry EntryFile" style={{ ...sx }}>
        <div className={cx("EntryFile__label", { __required: required })}>
          {label}
        </div>
        <TextField
          className="EntryText"
          key={Date.now()}
          name={name}
          label={hasValue ? "" : placeholder}
          value={fName}
          // ...
          InputLabelProps={{ shrink: false }}
          // ...
          variant="outlined"
          margin="dense"
          fullWidth
        />

        <input
          ref={inpRef}
          id={`file-${name}`}
          type="file"
          className="EntryFile__input"
          accept={accept}
          multiple={multiple}
          onChange={onChangeHandler}
        />

        <label className="EntryFile__btn" htmlFor={`file-${name}`}>
          <Button
            aria-label="upload picture"
            component="div"
            className={cx("EntryFile__btn__inner", {
              __compact: compact || isMobile,
              __medium: medium,
            })}
          >
            {btnLabel}
          </Button>
        </label>

        {preview && fUrl && (
          <div className="EntryFile__preview" style={{ ...previewStyle }}>
            <img src={fUrl} />
          </div>
        )}
      </Box>
    </Grid>
  );
};

export default EntryFile;
