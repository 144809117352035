import "./style.scss";

import { Box, Container, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { useHistory } from "react-router-dom";

import EventButton from "../../components/EventButton";
import EventEmptyView from "../../components/EventEmptyView";
import EventSectionTitle from "../../components/EventSectionTitle";
// ...
import Page from "../../components/Page";
import useStages from "../../hooks/useStages";
import StageCard from "./components/StageCard";

const EventStage = () => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const history = useHistory();
  const { isLoading, data: stages, onUpdate } = useStages();
  // ...
  const onAddStage = async () => {
    const { stageId, error } = await onUpdate();
    // ...
    if (error) return;
    if (parseInt(stageId)) {
      history.push(`/stages-schedules/${stageId}`);
    }
  };
  // ...
  return (
    <Page title="Stages and schedules">
      <Container>
        <Box>
          <EventSectionTitle
            title="Stages & schedules"
            subTitle="Create schedules and sessions"
            src="/static/setup/title/stages.svg"
          />

          {stages.length > 0 && (
            <EventSectionTitle
              title="View stages"
              subTitle="Review and manage stages"
            >
              <EventButton isLoading={isLoading} handler={() => onAddStage()}>
                {isMobile && "New"}
                {!isMobile && "New stage"}
              </EventButton>
            </EventSectionTitle>
          )}

          {stages.length === 0 && (
            <EventEmptyView
              src="/static/setup/empty/stages.svg"
              title="Add new schedule"
              desc="Manage event scenes and multiple schedules"
              btnLabel="Add new stage"
              handler={() => onAddStage()}
              isLoading={isLoading}
            />
          )}

          {stages.length > 0 && (
            <Box className="EventStages">
              <Box className="EventStages__cards">
                <Grid container spacing={isMobile ? 2 : 4}>
                  {stages.map((stage, idx) => (
                    <Grid key={idx} item xs={6} sm={6} md={3}>
                      <StageCard key={idx} data={stage} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default EventStage;
