import * as yup from "yup";

export const TABLE_HEAD = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Full name",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: true,
    label: "Company/Organization",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email address",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: true,
    label: "Phone number",
  },
  {
    id: "statusLabel",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
];

export const addSpeakerSchema = yup.object({
  firstName: yup.string().required().default(""),
  lastName: yup.string().required().default(""),
  biography: yup.string().required().default(""),
  position: yup.string().required().default(""),
  company: yup.string().required().default(""),
  email: yup.string().required().default(""),
  phoneNumber: yup.string().required().default(""),
  linkedin: yup.string().required().default(""),
  // ...
  image: yup.object().required().nullable().default(null),
});
