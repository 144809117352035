import { Grid, TextField } from "@material-ui/core";
import { TimePicker } from "@material-ui/lab";
import cx from "classnames";
import { useField } from "formik";
import React from "react";

const EntryTime = ({ required, label, topLabel, name, xs = 12, md = 6 }) => {
  const [field, meta, helpers] = useField({ name });
  // ...
  return (
    <Grid item xs={xs} md={md}>
      {topLabel && (
        <label
          style={{
            display: "block",
            color: "#333",
            fontFamily: "Poppins, sans-serif",
            fontSize: "13px",
            lineHeight: "20px",
            fontWeight: "500",
            margin: "0 0 5px 0",
          }}
        >
          {topLabel}
        </label>
      )}
      <TimePicker
        label={label}
        name={name}
        value={field.value}
        onChange={(date) => helpers.setValue(date)}
        onBlur={field.onBlur}
        error={meta.touched && !!meta.error}
        helperText={meta.error}
        // ...
        renderInput={(params) => (
          <TextField
            className={cx("Entry EntryTime", { __required: required })}
            {...params}
            // ...
            // onBlur={() => setTouched()}
            error={!!meta.error}
            helperText={meta.error}
            // ...
            margin="normal"
            fullWidth
          />
        )}
      />
    </Grid>
  );
};

export default EntryTime;
