import "./style.scss";

import { ExportToCsv } from "export-to-csv";
import { withFormik } from "formik";
import csvToJson from "papaparse";
import React, { useEffect } from "react";

import { EntryFile, EntryText } from "../../../../components/Entry";
import EventButton from "../../../../components/EventButton";
import EventForm from "../../../../components/EventForm";
import { validationSchema } from "../../config";
const iniValues = validationSchema.cast();

const AddAttendee = ({
  attendees,
  isLoading,
  data,
  onEditAttendee,
  ...props
}) => {
  const isEdit = !!data?.id;
  // ...
  const onBatchInsertAttendees = (key, files) => {
    const csv = files?.[0] || null;
    // ...
    if (csv) {
      csvToJson.parse(csv, {
        complete: function ({ data = [] }) {
          const attds = data
            .map((a) => ({
              email: a?.[0] || "",
              name: a?.[1] || "",
            }))
            .filter((a) => {
              var re = /\S+@\S+\.\S+/;
              return (
                re.test(a?.email || "") && attendees.indexOf(a?.email) === -1
              );
            });
          // ...
          onEditAttendee(attds);
        },
      });
    }
  };
  // ...
  const onExportCSV = async () => {
    const options = {
      filename: "attendees",
      headers: ["email", "name"],
      useKeysAsHeaders: false,
      showLabels: true,
      useBom: true,
    };
    // ...
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv([
      { email: "att@email.com", name: "Attendee name" },
    ]);
  };
  // ...
  const isDups = attendees.indexOf(props?.values?.email) > -1;
  // ...
  useEffect(() => {
    props.resetForm({ values: { ...iniValues, ...data } });
  }, []);
  // ...
  return (
    <div className="AddAttendee">
      <div className="AddAttendee__title __">
        Add single attendee via the form below
      </div>
      <EventForm spacing={3}>
        <EntryText
          label="Attendee Email"
          type="email"
          name="email"
          error={isDups ? "Attendee already in the list" : ""}
          md={12}
        />
        <EntryText label="Attendee full name" name="name" md={12} />
      </EventForm>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "20px 0 0 0",
        }}
      >
        <EventButton
          isLoading={isLoading}
          handler={() => onEditAttendee([props.values])}
        >
          Save Attendee
        </EventButton>
      </div>

      {!isEdit && (
        <>
          <div className="AddAttendee__sep" />

          <div className="AddAttendee__title">
            Or upload the attendees list (.CSV)
          </div>

          <div className="AddAttendee__title __sub">
            Step 1 :{" "}
            <span
              style={{ color: "#00AB55", fontWeight: "600", cursor: "pointer" }}
              onClick={onExportCSV}
            >
              Click here to download the template
            </span>
          </div>

          <div className="AddAttendee__title __sub __">
            Step 2 : Fill and upload the csv file
          </div>

          <EventForm>
            <EntryFile
              medium
              name="files"
              label="Only .csv files matching the template"
              placeholder="local files"
              onUpload={onBatchInsertAttendees}
              isLoading={isLoading}
              accept=".csv"
              md={12}
            />
          </EventForm>
        </>
      )}
    </div>
  );
};

const AddAttendeeWithFormik = withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(AddAttendee);

export default AddAttendeeWithFormik;
