import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import {
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
// ...
import {
  experimentalStyled as styled,
  useTheme,
} from "@material-ui/core/styles";
import React from "react";

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const TableToolbar = ({
  searchFor,
  setSearchFor,
  onDeleteHandler,
  numSelected,
}) => {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  return (
    <RootStyle
    // sx={{
    //   ...(numSelected > 0 && {
    //     color: isLight ? 'primary.main' : 'text.primary',
    //     bgcolor: isLight ? 'primary.lighter' : 'primary.dark'
    //   })
    // }}
    >
      <TextField
        placeholder="Search..."
        value={searchFor}
        onChange={(e) => setSearchFor(e.target.value)}
        sx={{ width: "35%" }}
        inputProps={{ sx: { height: "20px" } }}
      />

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={() => onDeleteHandler([1, 2, 3, 4])}>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
};

export default TableToolbar;
