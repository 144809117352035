// material
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { forwardRef, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

// utils
import track from "../../utils/analytics";

// ----------------------------------------------------------------------
// eslint-disable-next-line react/display-name
const Page = forwardRef(({ children, title = "", ...other }, ref) => {
  const { pathname } = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: pathname,
    });
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
