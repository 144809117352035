import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "EMAILS", default: [] });

const getEmailData = (a) => ({
  subject: a.subject,
  content: a?.content,
  category: a?.category,
  // ...
  files: a?.files || [],
});

export default function useEvent() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async () => {
    try {
      const emails = await HTTP.GET(
        `/organizations/events/${THE_ID}/getTemplates`
      );
      setAtom(emails || []);
    } catch (err) {
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  };
  // ...
  const onUpdate = useRecoilCallback(() => async (raw) => {
    setIsLoading(true);
    setHasError(false);

    let emailData = getEmailData(raw);
    if (raw.id) emailData.id = raw.id;

    try {
      await HTTP.POST(
        `/organizations/events/${THE_ID}/updateTemplateEmail`,
        emailData
      );
      // ...
      enqueueSnackbar(
        raw.id ? "Email template updated" : "Email template added",
        { variant: "success" }
      );
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return null;
    }
  });
  const onRemove = useRecoilCallback(() => async (emailId) => {
    setIsLoading(true);
    setHasError(false);

    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/deleteTemplatesEmail`, {
        ids: [emailId],
      });
      // ...
      enqueueSnackbar("Email template deleted", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return null;
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onUpdate,
    onRemove,
  };
}
