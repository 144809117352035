import "./style.scss";

import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";

import useFile from "../../hooks/useFile";
import {
  EntryAutocomplete,
  EntryCheckbox,
  EntryFile,
  EntrySelect,
  EntryText,
} from "../Entry";
import EventButton from "../EventButton";
import EventForm from "../EventForm";
import LoadingDots from "../LoadingDots";
import {
  EVENT_INDUSTRIES,
  EVENT_TAGS,
  SPONSOR_LEVEL,
  SPONSOR_TYPE,
  validationSchema,
} from "./entries";
const iniValues = validationSchema.cast();

const EventTypeStyle = styled("div")(() => ({
  position: "relative",
  margin: "0",
  "& > label": {
    display: "block",
    color: "rgb(99, 115, 129) !important",
    fontFamily: "Poppins,sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "300",
    margin: "0 0 15px 0",
  },
}));

const RegEntries = ({ name, value, options, handleChange, handleBlur }) => {
  // ...
  return (
    <RadioGroup
      row
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      style={{ flexWrap: "nowrap" }}
    >
      {options?.map((i, idx) => (
        <Box key={idx} sx={{ width: "150px" }}>
          <FormControlLabel
            value={i.value}
            label={i.label}
            control={<Radio />}
          />
        </Box>
      ))}
    </RadioGroup>
  );
};

const AddSponsor = ({
  data,
  check,
  // ...
  isReadOnly,
  onEditSponsor,
  onFetchSponsor,
  // ...
  isLoading: isLA,
  // ...
  ...props
}) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { isLoading: isLFD, onUpload: onUpload } = useFile();
  const { isLoading: isLFL, onUpload: onUploadL } = useFile();
  const { isLoading: isLFB, onUpload: onUploadB } = useFile();
  // ...
  const isLoading = isLA || isLFD || isLFL || isLFB;
  // ...
  const lSrc = props.values?.card?.url || null;
  const bSrc = props.values?.cover?.url || null;
  // ...
  const onUploadFileL = async (e) => {
    const files = e.target.files;
    // ...
    if (files?.length === 0) return;
    // ...
    const data = await onUploadL(files);
    props.setFieldValue("card", data?.[0] || {});
  };
  const onUploadFileB = async (e) => {
    const files = e.target.files;
    // ...
    if (files?.length === 0) return;
    // ...
    const data = await onUploadB(files);
    props.setFieldValue("cover", data?.[0] || {});
  };
  // ...
  const onUploadFile = async (key, files) => {
    const data = await onUpload(files);
    props.setFieldValue("blues", data?.[0] || {});
  };
  // ...
  useEffect(() => {
    props.resetForm({ values: { ...iniValues, ...data } });
  }, [data]);
  // ...
  const canSave = !!props?.values?.email;
  // ...
  return (
    <div className="AddSponsor">
      {check && (
        <div className="AddSponsor">
          <div className="AddAttendee__title __">Search for a sponsor</div>

          <EventForm>
            <EntryText label="Company email" name="email" xs={12} md={12} />
          </EventForm>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "50px 0 0 0",
            }}
          >
            <EventButton
              isLoading={isLoading}
              disabled={!props.values?.email}
              handler={() => onFetchSponsor(props.values?.email)}
            >
              Find Sponsor
            </EventButton>
          </div>
        </div>
      )}
      {!check && (
        <>
          <div className="sponsor__banner">
            {bSrc && (
              <div className="sponsor__banner__img">
                <input name="" type="file" onChange={onUploadFileB} />
                <img src={bSrc} />
                {isLFB && (
                  <div className="sponsor__banner__img__load">
                    <LoadingDots />
                  </div>
                )}
              </div>
            )}
            {!bSrc && (
              <div className="sponsor__banner__inner">
                <input type="file" onChange={onUploadFileB} />
                <div className="__icon">
                  {!isLFB && (
                    <img
                      src="/static/icons/upload.svg"
                      style={{
                        width: isMobile ? "19px" : "24px",
                        height: isMobile ? "19px" : "24px",
                      }}
                    />
                  )}
                </div>
                <div className="__label">
                  {isLFB ? <LoadingDots /> : "Upload cover photo"}
                </div>
              </div>
            )}
          </div>
          <div className="sponsor__photo">
            {lSrc && (
              <div className="sponsor__photo__img">
                <input type="file" onChange={onUploadFileL} />
                <img src={lSrc} />
                {isLFL && (
                  <div className="sponsor__photo__img__load">
                    <LoadingDots />
                  </div>
                )}
              </div>
            )}
            {!lSrc && (
              <div className="sponsor__photo__inner">
                <input type="file" onChange={onUploadFileL} />
                <div className="__icon">
                  {!isLFL && (
                    <img
                      src="/static/icons/upload.svg"
                      style={{
                        width: isMobile ? "18px" : "24px",
                        height: isMobile ? "18px" : "24px",
                      }}
                    />
                  )}
                </div>
                <div className="__label">
                  {isLFL && <LoadingDots />}
                  {!isLFL && isMobile && "Upload logo"}
                  {!isLFL && !isMobile && "Upload company logo"}
                </div>
              </div>
            )}
          </div>
          <EventForm>
            <Grid item xs={12} md={3} />
            <EntryText
              label="Company name"
              name="name"
              xs={12}
              md={4.5}
              disabled={isReadOnly}
            />
            <EntryText
              label="Slogan"
              name="slogan"
              xs={12}
              md={4.5}
              disabled={isReadOnly}
            />
            <EntryText
              label="About the company"
              name="description"
              multiline
              minRows={3}
              maxRows={3}
              md={12}
              disabled={isReadOnly}
            />
            <EntryAutocomplete
              freeSolo
              name="settings.industries"
              label="Industries"
              options={EVENT_INDUSTRIES}
              disabled={isReadOnly}
            />
            <EntryAutocomplete
              freeSolo
              name="settings.tags"
              label="Tags"
              options={EVENT_TAGS}
              disabled={isReadOnly}
            />
            <Grid item xs={6} md={6}>
              <EventTypeStyle>
                <label>Partnership type</label>
                <RegEntries
                  name="type"
                  value={props.values?.type}
                  handleChange={props.handleChange}
                  handleBlur={props.handleBlur}
                  options={SPONSOR_TYPE}
                />
              </EventTypeStyle>
            </Grid>
            <EntrySelect
              label="Partnership level"
              topLabel="Sponsorship / Partnership level"
              name="level"
              options={SPONSOR_LEVEL}
            />
            <EntryText
              label="Company email"
              name="email"
              disabled={isReadOnly}
            />
            <EntryText
              label="Company phone"
              name="phone"
              disabled={isReadOnly}
            />
            <EntryText
              label="Country"
              name="settings.country"
              disabled={isReadOnly}
            />
            <EntryText
              label="City"
              name="settings.city"
              disabled={isReadOnly}
            />
            <EntryText label="Website" name="website" disabled={isReadOnly} />
            <EntryText label="Facebook" name="facebook" disabled={isReadOnly} />
            <EntryText label="Linkedin" name="linkedin" disabled={isReadOnly} />
            <EntryText
              label="Instagram"
              name="instagram"
              disabled={isReadOnly}
            />
          </EventForm>
          <Grid container spacing={4} sx={{ marginTop: "5px" }}>
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={12}>
                <p
                  style={{
                    color: "#637381",
                    fontFamily: "Montserrat",
                    fontSize: "13px",
                    lineHeight: "16px",
                    fontWeight: "300",
                    marginBottom: "10px",
                  }}
                >
                  Feature sponsor / partner
                </p>
              </Grid>
              <EntryCheckbox
                name="settings.landing"
                label="Feature company on the event landing page"
                labelPlacement="end"
                xs={12}
                md={12}
              />
              <EntryCheckbox
                name="settings.venu"
                label="Feature company on the event venue"
                labelPlacement="end"
                xs={12}
                md={12}
              />
              <EntryCheckbox
                name="settings.emails"
                label="Send email invitation "
                labelPlacement="end"
                xs={12}
                md={12}
              />
            </Grid>
            <Grid container item xs={12} md={6} style={{ marginTop: "20px" }}>
              <EntryFile
                compact
                name="blues"
                label="Additional documents"
                placeholder="local file"
                onUpload={onUploadFile}
                xs={12}
                md={12}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "50px 0 0 0",
            }}
          >
            <EventButton
              isLoading={isLoading}
              disabled={!canSave}
              handler={() => onEditSponsor(props.values)}
            >
              Save Sponsor
            </EventButton>
          </div>
        </>
      )}
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(AddSponsor);
