import * as yup from "yup";

export const TABLE_HEAD = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Slot name",
  },
  {
    id: "startTime",
    numeric: false,
    disablePadding: true,
    label: "Start time",
  },
  {
    id: "duration",
    numeric: false,
    disablePadding: true,
    label: "Duration",
  },
  {
    id: "speakerId",
    numeric: false,
    disablePadding: true,
    label: "Speaker",
  },
];

export const getBadgeData = (d) => ({
  ...d,
  title: d.title || "",
  category: d.category || "attendees",
  textColor: d.textColor || "#000000",
  backgroundColor: d.backgroundColor || "#ffffff",
  backgroundImage: d.backgroundImage || null,
  text: d.text || "",
  subText1: d.subText1 || "",
  subText2: d.subText2 || "",
  subText3: d.subText3 || "",
});

export const validationSchema = yup
  .object({
    title: yup.string().required("Badge name is required").nullable(),
    category: yup.string().required().nullable(),
    // ...
    textColor: yup.string().notRequired(),
    backgroundColor: yup.string().notRequired().nullable(),
    backgroundImage: yup.object().nullable().notRequired(),
    // ...
    text: yup.string().nullable().notRequired(),
    subText1: yup.string().nullable().notRequired(),
    subText2: yup.string().nullable().notRequired(),
    subText3: yup.string().nullable().notRequired(),
  })
  .default({
    title: "",
    category: "attendees",
    textColor: "#363636",
    backgroundColor: "#ffffff",
    backgroundImage: null,
    text: "",
    subText1: "",
    subText2: "",
    subText3: "",
  });
