import * as yup from "yup";

export const TABLE_HEAD = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Ticket name",
  },
  {
    id: "priceLabel",
    numeric: false,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "conditionPreview",
    numeric: false,
    disablePadding: true,
    label: "Condition",
  },
];

export const addTicketSchema = yup.object({
  name: yup.string().required().default(""),
  price: yup.string().required().default(""),
  condition: yup.string().required().default(""),
});
