import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { createBrowserHistory } from "history";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Router } from "react-router-dom";
// ...
import { RecoilRoot } from "recoil";

import GoogleAnalytics from "./components/GoogleAnalytics";
import NotistackProvider from "./components/NotistackProvider";
import RtlLayout from "./components/RtlLayout";
// ...
import ScrollToTop from "./components/ScrollToTop";
import routes, { renderRoutes } from "./routes";
// ...
import ThemeConfig from "./theme";

const history = createBrowserHistory();

export default function App() {
  // ...
  return (
    <HelmetProvider>
      <RtlLayout>
        <ThemeConfig>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <NotistackProvider>
              <RecoilRoot>
                <Router history={history}>
                  <ScrollToTop />
                  <GoogleAnalytics />
                  {renderRoutes(routes)}
                </Router>
              </RecoilRoot>
            </NotistackProvider>
          </LocalizationProvider>
        </ThemeConfig>
      </RtlLayout>
    </HelmetProvider>
  );
}
