import { Box, Card, Container } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import EventBreadcrumbs from "../../components/EventBreadcrumbs";
import EventButton from "../../components/EventButton";
import EventEmptyView from "../../components/EventEmptyView";
import EventEntityTable from "../../components/EventEntityTable";
import EventHeader from "../../components/EventHeader";
import EventSectionTitle from "../../components/EventSectionTitle";
import ModalValidation from "../../components/ModalValidate";
// ...
import Page from "../../components/Page";
import useTickets from "../../hooks/useTickets";
import { TABLE_HEAD } from "./config";

// ..
const getCondPreview = (t) => {
  let label = "";
  if (t.conditionKey && t.conditionValue)
    label = `when"${t.conditionKey}"="${t.conditionValue}"`;
  // ...
  return label;
};

const EventTickets = () => {
  const isMobile = useMediaQuery("(max-width:850px)");
  const [delModal, setDelModal] = useState({
    open: false,
    id: null,
    label: "",
  });
  // ...
  const history = useHistory();
  const { isLoading, data: tickets, onCreate, onDelete } = useTickets();
  // ...
  const ticketsList = tickets.map((t) => ({
    ...t,
    priceLabel: t?.price || "",
    conditionPreview: getCondPreview(t),
  }));
  // ...
  const onAddTicket = async () => {
    const { ticketId, error } = await onCreate();
    // ...
    if (error) return;
    history.push(`/manage-tickets/${ticketId}`);
  };
  const onEditTicket = (data) => {
    history.push(`/manage-tickets/${data.id}`);
  };
  const onDeleteTicket = (id) => {
    onDelete(id);
    // ...
    setDelModal({ open: false, id: null, label: "" });
  };
  // ...
  return (
    <Page title="Ticketing">
      <Container>
        <Box>
          <EventSectionTitle
            title="Ticketing"
            subTitle="Create and manage event tickets"
            src="/static/setup/title/tickets.svg"
          />

          {ticketsList.length > 0 && (
            <EventSectionTitle
              title="View tickets list"
              subTitle="Review and manage tickets"
              sx={{ marginBottom: "30px !important" }}
            >
              <EventButton isLoading={isLoading} handler={() => onAddTicket()}>
                {isMobile && "New"}
                {!isMobile && "New ticket"}
              </EventButton>
            </EventSectionTitle>
          )}

          {ticketsList.length === 0 && (
            <EventEmptyView
              src="/static/setup/empty/tickets.svg"
              title="Create your first ticket"
              desc="Create and manage event tickets"
              btnLabel="Create new ticket"
              handler={onAddTicket}
              isLoading={isLoading}
            />
          )}

          {ticketsList.length > 0 && (
            <Box>
              <Card>
                <EventEntityTable
                  head={TABLE_HEAD}
                  data={ticketsList}
                  onEditHandler={(data) => onEditTicket(data)}
                  onDeleteHandler={(data) => {
                    setDelModal({
                      open: true,
                      id: data.id,
                      label: data?.title,
                    });
                  }}
                />
              </Card>
            </Box>
          )}
        </Box>
      </Container>

      <ModalValidation
        open={delModal.open}
        title="Delete sponsor"
        isLoading={isLoading}
        // ...
        yes={{
          label: "delete",
          handler: () => onDeleteTicket(delModal.id),
        }}
        no={{
          label: "cancel",
          handler: () => setDelModal({ open: false, id: null, label: "" }),
        }}
      >
        Are you sure to delete {delModal?.label || "ticket"}?
      </ModalValidation>
    </Page>
  );
};

export default EventTickets;
