import "./style.scss";

import { withFormik } from "formik";
import React, { useEffect } from "react";

import { EntryDate, EntryText, EntryTime } from "../../../../components/Entry";
import EventButton from "../../../../components/EventButton";
import EventForm from "../../../../components/EventForm";
import { addSlotSchema as validationSchema } from "../../entries";
import EntitiesAutocomplete from "../EntitiesAutocomplete";
const iniValues = validationSchema.cast();

const AddSlot = ({
  currDate,
  data = {},
  onEditSlot,
  isLoading,
  // ...
  speakersList = [],
  sponsorsList = [],
  // ...
  onAddNewSpeaker,
  onAddNewSponsor,
  ...props
}) => {
  const speakersOptions = speakersList.map((s) => ({
    label: s.firstName + " " + s.lastName,
    value: s.id,
  }));
  const sponsorsOptions = sponsorsList.map((s) => ({
    label: s.name,
    value: s.sponsor,
  }));
  // ...
  useEffect(() => {
    const sponsors = data?.sponsors?.map((s) => ({
      label: s.label || s.name,
      value: s.value || s.id,
    }));
    // ...
    let values = { ...iniValues, ...data, sponsors };
    // ...
    if (currDate) values.start = currDate;
    // ...
    props.resetForm({ values });
  }, []);
  // ...
  const canSave = !!props?.values?.duration;
  // ...
  return (
    <>
      <EventForm>
        <EntryText
          required
          label="Session title"
          placeholder="Session title"
          name="title"
          md={12}
        />
        <EntryDate required label="Start date" name="start" xs={4} md={4} />
        <EntryTime required label="Start time" name="start" xs={4} md={4} />
        <EntryText
          required
          label="Duration (minutes)"
          name="duration"
          type="number"
          xs={4}
          md={4}
        />
        <EntryText
          label="Description"
          name="description"
          placeholder="Session description"
          multiline
          minRows={4}
          maxRows={4}
          md={12}
        />
        <EntitiesAutocomplete
          name="speakers"
          addNewHandler={() => onAddNewSpeaker(props.values)}
          helper="Select speaker"
          placeholder="Select speaker"
          options={speakersOptions}
          sx={{ mt: "20px" }}
        />
        <EntitiesAutocomplete
          name="sponsors"
          addNewHandler={() => onAddNewSponsor(props.values)}
          helper="Select sponsor"
          placeholder="Select sponsor"
          options={sponsorsOptions}
          sx={{ mt: "20px" }}
        />
      </EventForm>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "50px 0 0 0",
        }}
      >
        <EventButton
          isLoading={isLoading}
          disabled={!canSave}
          handler={() => onEditSlot(props.values)}
        >
          Save Slot
        </EventButton>
      </div>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(AddSlot);
