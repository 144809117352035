import { Box, Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import EventSectionTitle from "../../components/EventSectionTitle";
import EventSetupStepper from "../../components/EventSetupStepper";
import EventTabPanel from "../../components/EventTabPanel";
import Page from "../../components/Page";
import useEvent from "../../hooks/useEvent";
import useRegFields from "../../hooks/useRegFields";
// ...
import { TABS_LIST } from "./config";
import { valSchemaBrand, valSchemaInfo, valSchemaVis } from "./entries";

const EventSetup = () => {
  const { data } = useEvent();
  const { data: dataR } = useRegFields();
  // ...
  const [currTab, setCurrTab] = useState(0);
  const [valSteps, setValSteps] = useState([false, false, false, false]);

  //# Handle BackBtn
  const onBackHandler = () => {
    const ps = currTab - 1;
    if (ps < 0) setCurrTab(1);
    setCurrTab(ps);
  };

  //#Validate Steps
  const onValidate = async () => {
    let _vals = [false, false, false];
    let _data = { ...data, ...dataR };
    // ...
    try {
      await valSchemaInfo.validate(_data);
      _vals[0] = true;
    } catch (err) {
      _vals[0] = false;
    }
    try {
      await valSchemaBrand.validate(_data);
      _vals[1] = true;
    } catch (err) {
      _vals[1] = false;
    }
    try {
      await valSchemaVis.validate(_data);
      _vals[2] = true;
    } catch (err) {
      _vals[2] = false;
    }
    // # Review Step
    _vals[3] = _data?.published;
    // ...
    setValSteps(_vals);
  };

  // ...
  useEffect(() => {
    onValidate();
  }, [data, dataR]);
  // ...
  return (
    <Page title="Event Setup">
      <Container>
        <Box>
          <EventSectionTitle
            title="Event setup"
            subTitle="Add the primary event details and publish your event"
            src="/static/setup/title/setup.svg"
          />

          <EventSetupStepper
            isFree={data?.isFree}
            valSteps={valSteps}
            // ...
            currTab={currTab}
            setCurrTab={(step) => setCurrTab(step)}
            ITEMS={TABS_LIST}
          />

          {TABS_LIST.map((tab, idx) => (
            <EventTabPanel key={idx} value={idx} currTab={currTab}>
              <tab.Node
                idx={idx}
                // ...
                currTab={currTab}
                valSteps={valSteps}
                setCurrTab={setCurrTab}
                onBackHandler={onBackHandler}
              />
            </EventTabPanel>
          ))}
        </Box>
      </Container>
    </Page>
  );
};

export default EventSetup;
