import * as yup from "yup";

export const TABLE_HEAD = [
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Email type",
  },
  {
    id: "subject",
    numeric: false,
    disablePadding: true,
    label: "Subject",
  },
  {
    id: "categoryLabel",
    numeric: false,
    disablePadding: true,
    label: "Category",
  },
];
