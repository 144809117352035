import "./style.scss";

import { Button, Card, Grid } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import ModalValidation from "../../../components/ModalValidate";
import useStages from "../../../hooks/useStages";

const CardMediaStyle = styled("div")(() => ({
  display: "flex",
  position: "relative",
  justifyContent: "center",
  paddingTop: "160px",
  // paddingTop: "140px",
}));
const CoverImgStyle = styled("img")({
  top: 0,
  zIndex: 9,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const StageCard = ({ data = {} }) => {
  const { isLoading, onDelete } = useStages();
  const history = useHistory();
  // const isMobile = useMediaQuery("(max-width:850px)");
  const [delModal, setDelModal] = useState(false);
  // ...
  const bannerSrc =
    data?.card?.url ||
    "https://csg.com.tr/assets/img/homepage/slider/hero-slider_3.jpg";
  //...
  const onEditStage = () => {
    history.push(`/stages-schedules/${data?.id}`);
  };
  // ...
  const onDeleteStage = async () => {
    await onDelete(data?.id);
    setDelModal(false);
  };
  // ...
  return (
    <Card
      className="StageCard"
      sx={{ width: { md: "252px", xs: "100%", borderRadius: "7px" } }}
    >
      <CardMediaStyle>
        <CoverImgStyle alt="cover" src={bannerSrc} />
      </CardMediaStyle>
      <p className="StageCard__label">{data.label || "Unnamed stage"}</p>
      <Grid
        container
        sx={{
          py: 3,
          display: "flex",
          flexDirection: { md: "row", xs: "column-reverse" },
          justifyContent: "flex-end",
          padding: "15px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setDelModal(true)}
          style={{
            background: "transparent",
            // color: "#FF4842",
            color: "#212b36",
            fontFamily: "Montserrat",
            fontSize: "12px",
            fontWeight: "700",
            textTransform: "uppercase",
            boxShadow: "none",
            padding: "5px 10px",
          }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          onClick={onEditStage}
          style={{
            color: "#fff",
            fontFamily: "Montserrat",
            fontSize: "12px",
            fontWeight: "700",
            textTransform: "uppercase",
            backgroundColor: "#00AB55",
            boxShadow: "none",
            borderRadius: "3px",
            width: "70px",
            padding: "5px 10px",
            margin: "0 0 0 10px",
          }}
        >
          Edit
        </Button>
      </Grid>

      <ModalValidation
        open={delModal}
        title="Delete stage"
        isLoading={isLoading}
        // ...
        yes={{
          label: "delete",
          handler: () => onDeleteStage(),
        }}
        no={{ label: "cancel", handler: () => setDelModal(false) }}
      >
        Are you sure to delete {data?.label || "stage"}?
      </ModalValidation>
    </Card>
  );
};

export default StageCard;
