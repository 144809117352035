import { Box, Container } from "@material-ui/core";
import React from "react";

import EventButton from "../../components/EventButton";
import EventEmptyView from "../../components/EventEmptyView";
import EventSectionTitle from "../../components/EventSectionTitle";
import Page from "../../components/Page";
import useEvent from "../../hooks/useEvent";

const demoDashLink =
  "https://datastudio.google.com/embed/reporting/a1eb0380-d577-4c66-9119-6b9563b1e384/page/1M";

const EventLive = () => {
  const { data } = useEvent();
  const hasLiveDashboard = data?.published;
  // ...
  const onOpenFullDash = () => {
    window?.open(demoDashLink);
  };
  // ...
  return (
    <Page title="Live dashboard">
      <Container>
        <Box>
          <EventSectionTitle
            title="Live insights"
            subTitle="Explore real time event analytics"
            src="/static/setup/title/dashboard.svg"
          />

          {hasLiveDashboard && (
            <EventSectionTitle
              title="Live insights"
              subTitle="Explore real time event analytics"
              sx={{ marginBottom: "30px !important" }}
            >
              <EventButton handler={onOpenFullDash}>
                <img
                  src="/static/icons/external.svg"
                  sx={{ width: 16, height: 16 }}
                />
                <span style={{ margin: "0 0 0 5px" }}>Open in new tab</span>
              </EventButton>
            </EventSectionTitle>
          )}

          {!hasLiveDashboard && (
            <EventEmptyView
              src="/static/setup/empty/dashboard.svg"
              title="Get realtime event insights"
              desc="Publish your event to get  real-time insights"
            />
          )}

          {hasLiveDashboard && (
            <Box>
              <iframe
                display="initial"
                src={demoDashLink}
                frameBorder="0"
                style={{ border: "none", width: "100%", height: "2000px" }}
              />
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default EventLive;
