export const PATH_EVENT = {
  root: "/",
  setup: "/event-setup",
  stages: "/stages-schedules",
  stageItem: "/stages-schedules/:id",
  speakers: "/manage-speakers",
  sponsors: "/sponsors-partners",
  attendees: "/manage-attendees",
  emails: "/manage-emails",
  tickets: "/manage-tickets",
  ticketItem: "/manage-tickets/:id",
  badges: "/manage-badges",
  badgeItem: "/manage-badges/:id",
  live: "/live-dashboard",
};
