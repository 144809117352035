import * as yup from "yup";

const MSG = `Dear [UserName], 

We are thrilled to invite you to attend the first AI Africa Hackathon,
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non diam facilisis, commodo libero et, commodo sapien. Pellentesque sollicitudin massa sagittis dolor facilisis, sit amet
vulputate nunc molestie. Pellentesque maximus nibh id luctus porta. Ut consectetur dui nec nulla mattis luctus. Donec nisi diam, congue vitae felis at, ullamcorper bibendum tortor. Vestibulum pellentesque felis felis. Etiam ac tortor felis. Ut elit arcu, rhoncus in laoreet vel, gravida sed tortor.
`;

export const validationSchema = yup.object({
  subject: yup.string().required().default(""),
  content: yup.string().required().default(MSG),
  category: yup.string().required().default("speaker"),
  // ...
  files: yup.array().default([]),
});
