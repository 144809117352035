export const TABLE_HEAD = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email address",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Collaboration type",
  },
  {
    id: "level",
    numeric: false,
    disablePadding: true,
    label: "Collaboration level",
  },
  {
    id: "statusLabel",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
];
