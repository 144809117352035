import "./style.scss";

import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import { Icon } from "@iconify/react";
import { AppBar, Box, Hidden, IconButton, Toolbar } from "@material-ui/core";
// ...
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import React from "react";
// ...
import { Link as RouterLink } from "react-router-dom";

import Logo from "../components/Logo";
import useEvent from "../hooks/useEvent";

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const AppNav = styled(AppBar)(({ theme }) => ({
  position: "relative",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  boxShadow: "0px 0.5px 0px #c4c5c7",
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const LogoStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  minHeight: APPBAR_MOBILE,
  boxShadow: "0px 0.5px 0px #c4c5c7",
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    // padding: theme.spacing(0, 5),
  },
}));

const DashboardNavbar = ({ onOpenSidebar }) => {
  const { data } = useEvent();
  // ...
  return (
    <AppNav className="DashboardNavbar">
      <ToolbarStyle>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>

        <LogoStyle>
          <RouterLink to="/" style={{ textDecoration: "none" }}>
            <Logo isFull />
          </RouterLink>
        </LogoStyle>

        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "44px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              borderRadius: "50%",
              width: "44px",
              height: "44px",
              overflow: "hidden",
              margin: "0 20px 0 0",
            }}
          >
            {data?.organization?.logo?.url && (
              <Box
                component="img"
                src={data.organization.logo.url}
                alt="Event Banner"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "22px",
              color: "#637381",
            }}
          >
            {data?.organization?.libele}
          </Box>
        </Box>
      </ToolbarStyle>
    </AppNav>
  );
};

export default DashboardNavbar;
