/* eslint-disable camelcase */
import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "STAGES", default: [] });

export default function useEvent() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async () => {
    try {
      const event = await HTTP.GET(`/organizations/events/${THE_ID}`);
      const stages = event?.stages || [];
      // ...
      setIsLoading(false);
      // ...
      setAtom(stages);
    } catch (err) {
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  };
  // ...
  const onUpdate = useRecoilCallback(() => async () => {
    setIsLoading(true);
    setHasError(false);

    try {
      // # Add new Stage
      let stageRes = await HTTP.POST(
        `/organizations/events/${THE_ID}/updateStage`,
        { event: THE_ID }
      );
      // ...
      enqueueSnackbar("Stage added", { variant: "success" });
      // ...
      return { stageId: stageRes?.id };
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  });
  const onDelete = useRecoilCallback(() => async (id) => {
    setIsLoading(true);
    setHasError(false);

    try {
      // # Delete Stage
      await HTTP.POST(`/organizations/events/${THE_ID}/updateStage`, {
        id: parseInt(id, 10),
        published_at: null,
      });
      // ...
      enqueueSnackbar("Stage deleted", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onUpdate,
    onDelete,
  };
}
