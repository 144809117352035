import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";

const RegEntries = ({ name, value, options, handleChange, handleBlur }) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  return (
    <RadioGroup
      row
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    >
      {options?.map((i, idx) => (
        <Box key={idx} sx={{ width: isMobile ? "33%" : "150px" }}>
          <FormControlLabel
            value={i.value}
            label={i.label}
            control={<Radio />}
          />
        </Box>
      ))}
    </RadioGroup>
  );
};

export default RegEntries;
