import { Grid, TextField } from "@material-ui/core";
import cx from "classnames";
import { useField } from "formik";
import React from "react";

const EntryText = ({
  required,
  // ...
  type = "text",
  label,
  name,
  placeholder,
  InputProps,
  // ...
  multiline,
  minRows,
  maxRows,
  // ...
  sx,
  topLabel,
  // ...
  disabled,
  error,
  // ...
  xs = 12,
  md = 6,
}) => {
  const [field, meta] = useField({ name });
  // ...
  let helperText = meta.touched ? meta.error : "";
  // ...
  if (error) {
    helperText = error;
  }
  // ...
  return (
    <Grid item xs={xs} md={md}>
      {topLabel && (
        <label
          style={{
            display: "block",
            color: "#333",
            fontFamily: "Poppins, sans-serif",
            fontSize: "13px",
            lineHeight: "20px",
            fontWeight: "500",
            margin: "0 0 5px 0",
          }}
        >
          {topLabel}
        </label>
      )}
      <TextField
        className={cx("Entry EntryText", { __required: required })}
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        value={field?.value || ""}
        onChange={field.onChange}
        onBlur={field.onBlur}
        disabled={disabled}
        // ...
        variant="outlined"
        margin="dense"
        fullWidth
        // ...
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        // ...
        InputProps={InputProps}
        // InputLabelProps={{ shrink: !!placeholder }}
        InputLabelProps={{ shrink: true }}
        error={meta.touched && (!!meta.error || error)}
        helperText={helperText}
        // ...
        sx={{ ...sx }}
      />
    </Grid>
  );
};

export default EntryText;
