import { useFormikContext } from "formik";
import React from "react";

import { EntrySelect, EntrySwitch, EntryText } from "../../../components/Entry";
import EventForm from "../../../components/EventForm";

const Details = ({ registrationFields }) => {
  const { values } = useFormikContext();
  // ...
  const { regFields = [] } = registrationFields;
  // ...
  const options = {};
  let conditionKeyOptions = [];
  regFields
    .filter((f) => f.type === "select")
    .forEach((o) => {
      options[o.label] = o?.settings?.options?.values || [];
      // ...
      conditionKeyOptions.push({ label: o.label, value: o.label });
    });
  // ...
  return (
    <div className="TicketStep __details">
      <h3 className="TicketStep__title">
        Create customized ticket for specific attendees
      </h3>
      <div className="TicketStep__groups">
        <div className="__group">
          <h3 className="__group__title">Add text to balance the UI</h3>
          <EventForm sx={{ mb: "60px" }}>
            <EntryText
              label="Ticket name"
              name="title"
              placeholder="Student pass"
              xs={12}
              md={12}
            />
            <EntrySwitch
              name="isFree"
              label="This ticket is free"
              labelPlacement="end"
              xs={6}
              md={6}
            />
            <EntrySwitch
              name="isActive"
              label="This ticket is active"
              labelPlacement="end"
              xs={6}
              md={6}
            />
            {!values?.isFree && (
              <>
                <EntryText
                  label="Ticket price"
                  name="price"
                  placeholder="15.00"
                  type="number"
                  xs={12}
                  md={6}
                />
                <EntrySelect
                  xs={12}
                  md={6}
                  label="Currency"
                  name="currency"
                  options={[
                    { label: "Option one", value: "one" },
                    { label: "Option two", value: "two" },
                  ]}
                />
              </>
            )}
          </EventForm>
        </div>
        <div className="__sep" />
        <div className="__group">
          <h3 className="__group__title">
            This ticket applies when a specific condition is checked
          </h3>
          <EventForm sx={{ mb: "60px" }}>
            <EntrySelect
              xs={12}
              md={12}
              label="When this field "
              name="conditionKey"
              options={conditionKeyOptions}
            />
            {/* <EntrySelect
              xs={12}
              md={12}
              label="Is equal ( = ) to"
              name="conditionValue"
              options={conditionValueOptions}
            /> */}
          </EventForm>
        </div>
      </div>
    </div>
  );
};

export default Details;
