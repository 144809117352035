import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "ATTENDEES", default: [] });

const getAttendeeData = (a) => ({
  email: a.email,
  name: a.name,
});

export default function useEvent() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async () => {
    try {
      const attendees = await HTTP.GET(
        `/organizations/events/${THE_ID}/getAttendees`
      );
      // ...
      setAtom(attendees || []);
    } catch (err) {
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  };
  // ...
  const onUpdate = useRecoilCallback(() => async (raw) => {
    setIsLoading(true);
    setHasError(false);

    const attendees = raw.map((a) => {
      let att = getAttendeeData(a);
      if (a.id) att.id = a.id;
      // ...
      return att;
    });
    // ...
    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/createInvitations`, {
        invitations: attendees,
      });
      // ...
      enqueueSnackbar("Attendee added", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return null;
    }
  });
  const onRemove = useRecoilCallback(() => async (id) => {
    setIsLoading(true);
    setHasError(false);

    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/deleteInvitations`, {
        ids: [id],
      });
      // ...
      enqueueSnackbar("Attendee deleted", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return null;
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onUpdate,
    onRemove,
  };
}
