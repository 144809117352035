import { useFormikContext } from "formik";
import React from "react";

import { EntryColor, EntryFile, EntryText } from "../../../components/Entry";
import EventForm from "../../../components/EventForm";
import useFile from "../../../hooks/useFile";

const Design = () => {
  const { onUpload } = useFile();
  const { setFieldValue } = useFormikContext();
  // ...
  const onUploadFile = async (key, files) => {
    const data = await onUpload(files);
    setFieldValue([key], data[0]);
  };
  // ..
  return (
    <div className="TicketStep __design">
      <h3 className="TicketStep__title">Ticket builder</h3>
      <div className="TicketStep__groups">
        <div className="__group">
          <EventForm>
            <EntryColor
              label="Text color"
              name="textColor"
              placeholder="white"
              xs={6}
              md={3}
            />
            <EntryColor
              label="Background color"
              name="backgroundColor"
              placeholder="white"
              xs={6}
              md={3}
            />
            <EntryFile
              compact
              label="Ticket Banner"
              name="bannerImage"
              placeholder="Local image"
              onUpload={onUploadFile}
              xs={12}
              md={3}
            />
            <EntryFile
              compact
              label="Background image"
              name="backgroundImage"
              placeholder="Local image"
              onUpload={onUploadFile}
              xs={12}
              md={3}
            />
          </EventForm>
        </div>
        <div className="__group __typo">
          <div className="__title">Heading</div>
          <div className="__subgroups">
            <div className="__subgroups__group __left">
              <EventForm>
                <EntryText
                  name="heading"
                  label="Header"
                  placeholder="Event name"
                  xs={12}
                  md={12}
                />
                <EntryText
                  name="subHeading1"
                  label="Sub heading 1"
                  placeholder="Event date"
                  xs={12}
                  md={12}
                />
                <EntryText
                  name="subHeading2"
                  label="Sub heading 2"
                  placeholder="Event location"
                  xs={12}
                  md={12}
                />
                <EntryText
                  name="subHeading3"
                  label="Sub heading 3"
                  placeholder="Organization"
                  xs={12}
                  md={12}
                />
              </EventForm>
            </div>
            <div className="__subgroups__group __right">
              <EventForm>
                <EntryText
                  name="paragraph"
                  label="Paragraph"
                  placeholder="Event short description"
                  multiline
                  minRows={5}
                  xs={12}
                  md={12}
                />
              </EventForm>
            </div>
          </div>
        </div>
        <div className="__group __typo">
          <div className="__title">Text</div>
          <div className="__subgroups">
            <div className="__subgroups__group __left">
              <EventForm>
                <EntryText
                  name="text"
                  label="Text"
                  placeholder="Full name"
                  xs={12}
                  md={12}
                />
                <EntryText
                  name="subText1"
                  label="Sub heading 1"
                  placeholder="Company"
                  xs={12}
                  md={12}
                />
                <EntryText
                  name="subText2"
                  label="Sub heading 2"
                  placeholder="Sector"
                  xs={12}
                  md={12}
                />
                <EntryText
                  name="subText3"
                  label="Sub heading 3"
                  placeholder="Email"
                  xs={12}
                  md={12}
                />
              </EventForm>
            </div>
            <div className="__subgroups__group __right __qr">
              <div className="__qr-bar">
                <img src="/static/icons/ticket_qr.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Design;
