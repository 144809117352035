import * as yup from "yup";

export const validationSchema = yup.object({
  firstName: yup.string().required().default(""),
  lastName: yup.string().required().default(""),
  description: yup.string().required().default(""),
  position: yup.string().required().default(""),
  company: yup.string().required().default(""),
  email: yup.string().required().default(""),
  phoneNumber: yup.string().required().default(""),
  linkedin: yup.string().required().default(""),
  // ...
  isModerator: yup.boolean().required().default(false),
  sendEmailNotification: yup.boolean().required().default(false),
  // ...
  image: yup.object().required().nullable().default(null),
});
