import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const email = {
  label: "Email",
  type: "text",
  required: true,
  settings: {
    idx: 0,
    min: "",
    max: "",
    options: [],
    base: true,
  },
};

const getFieldData = (fields = [email]) => {
  return fields.map((f) => ({
    label: f.label,
    type: f.type,
    required: f.required,
    settings: {
      idx: f?.settings?.idx,
      min: f?.settings?.min,
      max: f?.settings?.max,
      options: f?.settings?.options || [],
      base: f?.settings?.base,
    },
  }));
};

const ATOM = atom({
  key: "REG_FIELDS",
  default: { registration: null, regLabel: "easy-go", regFields: [email] },
});

export default function useRegFields() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ..
  const onUpdate = useRecoilCallback(() => async (data) => {
    try {
      const res = await HTTP.POST(
        `/organizations/events/${THE_ID}/updateRegistration`,
        {
          event: THE_ID,
          label: data?.regLabel || "",
          fields: getFieldData(data?.regFields),
        }
      );
      const regLabel = res?.label || "easy-go";
      const regFields = res?.fields?.length > 0 ? res?.fields : [email];
      // ...
      setAtom({ registration: res.id, regLabel, regFields });
      // ...
      return {};
    } catch (err) {
      enqueueSnackbar("ERROR", { variant: "error" });
      return { error: true };
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    async function fetchMyAPI() {
      const res = await HTTP.POST(
        `/organizations/events/${THE_ID}/updateRegistration`,
        { event: THE_ID }
      );
      const regLabel = res?.label || "";
      const regFields = res?.fields?.length > 0 ? res?.fields : [email];
      // ...
      setAtom({ registration: res.id, regLabel, regFields });
    }
    // ...
    fetchMyAPI();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onUpdate,
  };
}
