import * as yup from "yup";

export const validationSchema = yup.object({
  name: yup.string().required().default(""),
  slogan: yup.string().required().default(""),
  description: yup.string().required().default(""),
  type: yup.string().required().default("sponsor"),
  level: yup.string().required().default("silver"),
  email: yup.string().required().default(""),
  phone: yup.string().required().default(""),
  website: yup.string().required().default(""),
  facebook: yup.string().required().default(""),
  linkedin: yup.string().required().default(""),
  instagram: yup.string().required().default(""),
  // ...
  settings: yup.object({
    country: yup.string().required().default(""),
    city: yup.string().required().default(""),
    // ...
    industries: yup.object().required().default({}),
    tags: yup.object().required().default({}),
    // ...
    landing: yup.boolean().default(false),
    venu: yup.boolean().default(false),
    emails: yup.boolean().default(false),
  }),
  // ...
  card: yup.object().required().nullable().default(null),
  cover: yup.object().required().nullable().default(null),
  blues: yup.object().required().nullable().default(null),
});

export const EVENT_INDUSTRIES = [
  { label: "Alimentation", value: "alimentation" },
  { label: "Construction", value: "construction" },
  { label: "Distribution", value: "distribution" },
  { label: "Électronique", value: "electronique" },
  { label: "Énergie et matériel", value: "energie-et-matériel" },
];
export const EVENT_TAGS = [
  { label: "TAG 1", value: "tag-1" },
  { label: "TAG 2", value: "tag-2" },
  { label: "TAG 3", value: "tag-3" },
  { label: "TAG 4", value: "tag-4" },
  { label: "TAG 5", value: "tag-5" },
];

export const SPONSOR_TYPE = [
  { label: "Partner", value: "partner" },
  { label: "Sponsor", value: "sponsor" },
  { label: "Exhibitor", value: "exhibitor" },
];
export const SPONSOR_LEVEL = [
  { label: "Platinium", value: "platinium" },
  { label: "Gold", value: "gold" },
  { label: "Silver", value: "silver" },
];
