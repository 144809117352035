import * as yup from "yup";

export const valSchemaInfo = yup.object({
  title: yup.string().required().default(""),
  slug: yup.string().required().default(""),
  // ...
  address: yup.string().required().default(""),
  startDate: yup
    .date()
    .required()
    .default(new Date())
    .when("endDate", (endDate, schema) => endDate && schema.max(endDate)),
  endDate: yup
    .date()
    .required()
    .default(new Date())
    .min(yup.ref("startDate", "End date should be later than start date")),
  shortDescription: yup.string().required().default(""),
  description: yup.string().notRequired().default(""),
  type: yup.string().required().default("virtual"),
  category: yup.string().required().default(""),
  tags: yup.object().required().default({}),
  requireVisio: yup.boolean().default(false),
  isFree: yup.string().default("free"),
});

export const stupidSlugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, "").toLowerCase();
  // ...
  var from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }
  // ...
  return str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
};

export const getNewDates = () => {
  const now = new Date();
  const next = new Date();
  next.setDate(next.getDate() + 1);
  // ...
  const startDate = now.toISOString();
  const endDate = next.toISOString();
  // ...
  return { startDate, endDate };
};
