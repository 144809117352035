import "./style.scss";

import Dialog from "@material-ui/core/Dialog";
import { useFormikContext } from "formik";
import React from "react";

import EventButton from "../../../../components/EventButton";
import useEvent from "../../../../hooks/useEvent";

const BadgePreviewSide = ({ event = {}, data = {}, style = {} }) => {
  return (
    <div className="__side __user" style={{ ...style }}>
      <div className="__logo" />
      <div className="__title">{event.title}</div>
      <div className="__details">
        <div className="__details__body">
          <div className="__info">
            <div className="__info__num">
              <span className="__label">Ticket3 </span>
              <span className="__value">#58413</span>
            </div>
            <div className="__info__name">{data?.text || ""}</div>
            <div className="__info__pos">Data science intern</div>
            <div className="__info__org">{data?.subText1 || ""}</div>
          </div>
          <div className="__qr">
            <img src="/static/icons/qr.svg" alt="" />
          </div>
        </div>
        <div className="__details__type">{data?.subText3 || ""}</div>
      </div>
      <div className="__date">
        Monday , December 27th 2021 at 8:00 AM Friday 31st December 2021 at 3PM
      </div>
      <div className="__who">{data?.subText2 || ""}</div>
    </div>
  );
};

const BadgePreviewPartners = () => {
  return (
    <div className="__side __partners">
      <div className="__title">In Partnership with</div>
      <div>
        <img src="/static/icons/tmp_badge.svg" alt="" />
      </div>
    </div>
  );
};

const BadgePreviewNote = () => {
  return (
    <div className="__side __note">
      <div className="__title">Welcome note</div>
      <p>
        We are thrilled to invite you to attend the first AI Africa Hackathon,
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non
        diam facilisis, commodo libero et, commodo sapien. Pellentesque
        sollicitudin massa sagittis dolor facilisis, sit amet vulputate nunc
        molestie.
      </p>
    </div>
  );
};

const BadgePreview = ({ open, setModal }) => {
  const { data: event } = useEvent();
  const { values } = useFormikContext();
  // ...
  const {
    textColor,
    backgroundColor = "#fff",
    backgroundImage,
    // ...
    text,
    subText1,
    subText2,
    subText3,
  } = values || {};
  // ...
  return (
    <Dialog
      className="BadgePreviewDialog"
      open={open}
      onClose={() => setModal(false)}
      maxWidth={false}
    >
      <div className="BadgePreview" style={{ color: textColor }}>
        <div className="BadgePreview__header">
          <p className="BadgePreview__header__title">Badge preview</p>
          <EventButton
            handler={() => setModal(false)}
            sx={{ backgroundColor: "red" }}
          >
            Exit Preview
          </EventButton>
        </div>
        <div className="BadgePreview__content scrollbar">
          <div className="__group __top">
            <BadgePreviewSide
              event={event}
              data={{ text, subText1, subText2, subText3 }}
              style={{
                backgroundColor,
                backgroundImage: `url(${backgroundImage?.url})`,
                backgroundSize: "cover",
              }}
            />
            <BadgePreviewSide
              event={event}
              data={{ text, subText1, subText2, subText3 }}
              style={{
                backgroundColor,
                backgroundImage: `url(${backgroundImage?.url})`,
                backgroundSize: "cover",
              }}
            />
          </div>
          {/* <div className="__group __bottom">
            <BadgePreviewPartners />
            <BadgePreviewNote />
          </div> */}
        </div>
      </div>
    </Dialog>
  );
};

export default BadgePreview;
