import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import React, { useState } from "react";

const Status = withStyles(() => ({
  root: {
    padding: "5px 10px",
    borderRadius: "5px",
    color: "white",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "20px",
    textTransform: "capitalize",
    "&.__invited": {
      color: "#229A16",
      backgroundColor: "rgba(84, 214, 44, 0.16)",
    },
    "&.__declined": {
      color: "white",
      backgroundColor: "#FF4842",
    },
  },
}))(({ classes, val }) => {
  return (
    <span
      className={cx(
        classes.root,
        { __invited: val === "planned" },
        { __invited: val === "invited" },
        { __declined: val === "none" }
      )}
    >
      {val}
    </span>
  );
});

export default Status;
