import deafIcon from "@iconify/icons-la/deaf";
import { Icon } from "@iconify/react";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import VideocamIcon from "@material-ui/icons/Videocam";
import React from "react";
import * as yup from "yup";

// - Event Info
export const EVENT_TICKITING = [
  { value: "paid", label: "Paid" },
  { value: "free", label: "free" },
];

export const EVENT_TYPES = [
  { label: "Virtual", value: "virtual" },
  { label: "Hybrid", value: "hybrid" },
  { label: "Physical", value: "physical" },
];

export const EVENT_FEES = [
  { label: "Free", value: "free" },
  { label: "Paid", value: "paid" },
];

export const EVENT_CATEGORIES = [
  { label: "Event", value: "event" },
  { label: "Conference", value: "conference" },
  { label: "Fair", value: "fair" },
  { label: "Training", value: "training" },
  { label: "Bootcamp", value: "bootcamp" },
  { label: "Show", value: "show" },
  { label: "Other", value: "other" },
];

export const EVENT_TAGS = [
  { label: "Tag 1", value: "tag-1" },
  { label: "Tag 2", value: "tag-2" },
  { label: "Tag 3", value: "tag-3" },
  { label: "Tag 4", value: "tag-4" },
  { label: "Tag 5", value: "tag-5" },
];

export const STREAM_TYPES = [
  {
    label: "Live stream",
    value: "live-stream",
    icon: <VideocamIcon sx={{ fontSize: "50px" }} />,
  },
  {
    label: "Audio, tran.",
    value: "audio-trans",
    icon: <HeadsetMicIcon style={{ fontSize: "50px" }} />,
  },
  {
    label: "Sign lang.",
    value: "sign-lang",
    icon: <Icon icon={deafIcon} style={{ fontSize: "60px" }} />,
  },
];

export const EVENT_VISIBILITIES = [
  { value: "public", label: "Public" },
  { value: "restricted", label: "Restricted" },
  { value: "private", label: "Private" },
];
export const EVENT_VISIBILITIES_DETAILS = {
  public: {
    icon: "/static/icons/reg_public.svg",
    title: "Public, everyone can register and attend the event",
    desc:
      "Your event will be visibile on evey.live , everyone can register and attend it.",
  },
  restricted: {
    icon: "/static/icons/reg_restricted.svg",
    title: "Restricted , only people with access code can register",
    desc:
      "Your event will be visibile on evey.live , but the registration requires the event access code.",
  },
  private: {
    icon: "/static/icons/reg_private.svg",
    title: "Private, only invited people can access",
    desc:
      "Your event will be visible only to invited people , or people having event link and access code.",
  },
};

export const REGISTRATION_METHODS = [
  { value: "easy-go", label: "Easy Go" },
  { value: "standard", label: "Standard" },
  { value: "customized", label: "Customized" },
];
export const REGISTRATION_METHODS_DETAILS = {
  "easy-go": {
    icon: "/static/icons/reg_easy.svg",
    title: "Easy go, only name and email are required to register",
    desc:
      "Attendees will enter quickly to your event , this method is recommended for fast conversion.",
  },
  standard: {
    icon: "/static/icons/reg_std.svg",
    title: "Standard, Attendee must create an account to access",
    desc:
      "Attendees will have to create and account on evey.live to access to your event.",
  },
  customized: {
    icon: "/static/icons/reg_custom.svg",
    title: "Customized, attendee have to register and enter extra details",
    desc:
      "Attendees will have to create and account on evey.live and add extra details to access to your event.",
  },
};

export const valSchemaInfo = yup.object({
  title: yup.string().nullable().required("Event name is required").default(""),
  slug: yup.string().nullable().required("Event slug is required").default(""),
  // ...
  address: yup
    .string()
    .nullable()
    .required("Event location is required")
    .default(""),
  startDate: yup
    .date()
    .nullable()
    .required()
    .default(new Date())
    .when(
      "endDate",
      (endDate, schema) =>
        endDate &&
        schema.max(endDate, "Start date must be greater than end date")
    ),
  endDate: yup
    .date()
    .nullable()
    .required()
    .default(new Date())
    .min(yup.ref("startDate"), "End date should be later than start date"),
  shortDescription: yup
    .string()
    .nullable()
    .required(" Short description is required")
    .default(""),
  description: yup.string().nullable().notRequired().default(""),
  type: yup
    .string()
    .nullable()
    .required("Event type is required")
    .default("virtual"),
  category: yup
    .string()
    .nullable()
    .required("Event category is required")
    .default(""),
  tags: yup.object().nullable().notRequired().default({}),
  requireVisio: yup.boolean().default(false),
  isFree: yup.string().nullable().default("free"),
});

export const valSchemaBrand = yup
  .object({
    cover: yup.array().nullable().required().min(1),
    card: yup.object().nullable().required(),
    // ...
    linkedin: yup.string().nullable().notRequired().default(""),
    facebook: yup.string().nullable().notRequired().default(""),
    videoCover: yup.string().nullable().notRequired().default(""),
    website: yup.string().nullable().notRequired().default(""),
  })
  .default({
    cover: null,
    card: null,
    // ...
    linkedin: "",
    facebook: "",
    videoCover: "",
    website: "",
  });

export const valSchemaVis = yup.object({
  access: yup.string().nullable().required().default(""),
  password: yup.string().nullable().notRequired().default(""),
  // ...
  regLabel: yup.string().nullable().required().default(""),
  regFields: yup.array().nullable().default([]),
});

export const stupidSlugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, "").toLowerCase();
  // ...
  var from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }
  // ...
  return str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
};
