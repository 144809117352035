import "./style.scss";

import { Box, Card, Container, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useHistory, useParams } from "react-router-dom";

import AddSpeaker from "../../components/AddSpeaker";
import AddSponsor from "../../components/AddSponsor";
import { EntryFile, EntrySwitch, EntryText } from "../../components/Entry";
import EventActions from "../../components/EventActions";
import EventButton from "../../components/EventButton";
import EventEntityDrawer from "../../components/EventEntityDrawer";
import EventEntityTable from "../../components/EventEntityTable";
import EventForm from "../../components/EventForm";
import EventSectionTitle from "../../components/EventSectionTitle";
import Page from "../../components/Page";
import useFile from "../../hooks/useFile";
import useSlots from "../../hooks/useSlots";
import useSpeakers from "../../hooks/useSpeakers";
import useSponsors from "../../hooks/useSponsors";
import useStage from "../../hooks/useStage";
import { dateToID, getSlotTime } from "../../utils/dates";
import AddSlot from "./components/AddSlot";
import DatesAdd from "./components/DatesAdd";
// ...
import {
  getDatesFromSlots,
  stageSchema as validationSchema,
  TABLE_HEAD,
} from "./entries";
const iniValues = validationSchema.cast();

const SlotStartTime = ({ time }) => {
  if (!time) return "";
  return <Moment format="hh:mm a">{time}</Moment>;
};
const SlotSpeaker = ({ slot }) => {
  const name = slot?.speakers?.[0]?.firstName || "";
  return name || "";
};

const EventStageItem = ({ ...props }) => {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { id: stageId } = useParams();
  const { isLoading: isLS, data: stage, onUpdate } = useStage(stageId);
  const {
    isLoading: isLSS,
    data: slots,
    onUpdate: onUpdateSlot,
    onRemove: onRemoveSlot,
    onUpdateDates,
  } = useSlots(stageId);
  const {
    isLoading: isLSPKS,
    data: speakersList,
    onCreate: onCreateSpeaker,
  } = useSpeakers();
  const {
    isLoading: isLSPNS,
    data: sponsorsList,
    onUpdate: onCreateSponsor,
    onFetch,
  } = useSponsors();
  const { isLoading: isLF, onUpload } = useFile();
  // ...
  const isLoading = isLS || isLSS || isLSPKS || isLSPNS || isLF;
  // ...
  const [drawer, setDrawer] = useState({ open: false, data: {} });
  // ...
  const [drawerSpeaker, setDrawerSpeaker] = useState({ open: false, data: {} });
  // ...
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [drawerSponsor, setDrawerSponsor] = useState({
    open: false,
    data: {},
    check: true,
  });
  // ...
  const [dateTab, setDateTab] = useState(0);
  const [datesList, setDatesList] = useState([]);
  const hasDates = datesList?.length > 0;
  const currDate = datesList?.[dateTab];
  // ...
  const slotsList = slots
    ?.filter((s) => dateToID(s.start) === currDate)
    ?.map((s) => {
      let durationLabel = "";
      if (s.duration) durationLabel = s.duration + " min";
      // ...
      return {
        ...s,
        durationLabel,
        startTime: <SlotStartTime time={s.start} />,
        speakerId: <SlotSpeaker slot={s} />,
      };
    });
  // ...
  const onEditStage = async () => {
    await onUpdate(props.values);
    history.push("/stages-schedules");
  };
  // ...
  const onEditSlots = async (idx, date) => {
    const tmp =
      slots
        ?.filter((s) => dateToID(s.start) === currDate)
        ?.map((s) => {
          return { id: s.id, start: getSlotTime(date, s.start) };
        }) || [];
    // ...
    if (tmp.length > 0) {
      await onUpdateDates(tmp);
    }
    // ...
    setDatesList((dates) =>
      dates
        .map((d, ix) => (idx === ix ? date : d))
        .sort((a, b) => (a > b ? 1 : -1))
    );
  };
  // ...
  const onEditSlot = async (slot) => {
    await onUpdateSlot(slot);
    setDrawer({ open: false, data: {} });
  };
  const onDeleteSlot = (slot) => {
    onRemoveSlot(slot);
  };
  // ...
  const onAddSpeaker = async (data) => {
    await onCreateSpeaker(data);
    setDrawerSpeaker({ open: false, data: {}, idx: "" });
    setDrawer((s) => ({ ...s, open: true }));
  };
  // ...
  const onAddSponsor = async (data) => {
    await onCreateSponsor(data);
    setDrawerSponsor({ open: false, data: {}, idx: "", check: true });
    setDrawer((s) => ({ ...s, open: true }));
  };
  // ...
  const onFetchSponsor = async (email) => {
    const s = await onFetch(email);
    // ...
    if (s) {
      let sponsor = { ...s, sponsor: s.id };
      delete sponsor.id;
      // ...
      setDrawerSponsor((o) => ({
        ...o,
        data: { ...o.data, ...sponsor, type: "sponsor", level: "silver" },
        check: false,
      }));
      setIsReadOnly(true);
    } else {
      setDrawerSponsor((o) => ({ ...o, check: false }));
      setIsReadOnly(false);
    }
  };
  // ...
  const onUploadFile = async (key, files) => {
    const data = await onUpload(files);
    const ids = data?.map((d) => d.id);
    await onUpdate({ id: parseInt(stageId, 10), [key]: ids[0] });
  };
  // ...
  useEffect(() => {
    const datesList = /* Stupid API */ getDatesFromSlots(slots);
    setDatesList(datesList);
  }, [slots]);
  // ...
  useEffect(() => {
    props.resetForm({ values: { ...iniValues, ...stage } });
  }, [stage]);
  // ...
  let bcLinks = [
    { name: "Event management" },
    { name: "Stages and schedules" },
    { name: stage?.label || "New stage" },
  ];
  if (isMobile) {
    delete bcLinks[2];
  }
  // ...
  return (
    <Page title="Stages and schedules">
      <Container>
        <Box>
          <EventSectionTitle
            title="Stages & schedules"
            subTitle="Create schedules and sessions"
            src="/static/setup/title/stages.svg"
          />

          <Grid container spacing={8}>
            <EventForm
              item
              xs={12}
              md={6}
              alignContent="flex-start"
              alignItems="flex-start"
            >
              <EntryText
                required
                label="Stage name"
                name="label"
                placeholder="Stage name"
                xs={12}
                md={12}
              />
              <EntryText
                required
                label="Stage description"
                name="description"
                placeholder="Stage description"
                multiline
                minRows={4}
                maxRows={4}
                sx={{ margin: "40px 0 0 0 !important" }}
                xs={12}
                md={12}
              />
              <EntrySwitch
                name="requireVisio"
                label="This event requires a visio conference"
                xs={12}
                md={12}
              />
            </EventForm>
            <EventForm item xs={12} md={6} sx={{ mb: "60px", height: "200px" }}>
              <EntryFile
                required
                label="Stage banner (600 x 400 px)"
                placeholder="Select stage banner"
                name="card"
                onUpload={onUploadFile}
                xs={12}
                md={12}
              />
              <EntryFile
                label="Stage documents"
                placeholder="brochure, welcome pack, etc."
                name="cover"
                onUpload={onUploadFile}
                sx={{ margin: "40px 0 0 0" }}
                xs={12}
                md={12}
              />
            </EventForm>
          </Grid>

          <EventSectionTitle
            title="Stage schedule"
            subTitle="Create schedules and sessions for this stage"
            sx={{ marginTop: "70px" }}
          >
            <EventButton
              isLoading={isLoading}
              handler={() => setDrawer({ open: true, _id: null })}
            >
              {isMobile && "Add"}
              {!isMobile && "Add new session"}
            </EventButton>
          </EventSectionTitle>

          {hasDates && (
            <>
              <DatesAdd
                currDate={currDate}
                hasDates={hasDates}
                datesList={datesList}
                setDatesList={setDatesList}
                dateTab={dateTab}
                setDateTab={setDateTab}
                // ...
                onEditSlots={onEditSlots}
              />

              <Box className="EventStageItem__group __title">
                <span>Schedule of </span>
                <span>
                  <Moment format="D MMM YYYY">{currDate}</Moment>
                </span>
              </Box>
              <Box className="EventStageItem__group">
                {datesList !== 0 && (
                  <Card sx={{ borderRadius: "0 0 8px 8px" }}>
                    <EventEntityTable
                      label="slot"
                      head={TABLE_HEAD}
                      data={slotsList}
                      onEditHandler={(data) => setDrawer({ open: true, data })}
                      onDeleteHandler={(data) => onDeleteSlot(data)}
                    />
                  </Card>
                )}
              </Box>
            </>
          )}
        </Box>

        <EventEntityDrawer
          open={drawer.open}
          closeDrawer={() => setDrawer({ open: false, data: {} })}
          title="Add new session"
          sub="Enter slot details"
          width="800px"
        >
          <AddSlot
            currDate={currDate}
            data={drawer.data}
            onEditSlot={onEditSlot}
            isLoading={isLoading}
            // ...
            speakersList={speakersList}
            sponsorsList={sponsorsList}
            // ...
            onAddNewSpeaker={(data) => {
              setDrawer({ open: false, data });
              setDrawerSpeaker({ open: true, data: {}, idx: "" });
            }}
            onAddNewSponsor={(data) => {
              setDrawer({ open: false, data });
              setDrawerSponsor({ open: true, data: {}, idx: "", check: true });
            }}
          />
        </EventEntityDrawer>

        <EventEntityDrawer
          open={drawerSpeaker.open}
          closeDrawer={() => {
            setDrawer((s) => ({ ...s, open: true }));
            setDrawerSpeaker({ open: false, data: {}, idx: "" });
          }}
          title="Add new speaker"
          sub="Enter speaker details"
          width="800px"
        >
          <AddSpeaker
            idx={drawerSpeaker.idx}
            data={drawerSpeaker.data}
            onAddSpeaker={onAddSpeaker}
            onEditSpeaker={null}
            isLoading={isLoading}
          />
        </EventEntityDrawer>
        <EventEntityDrawer
          open={drawerSponsor.open}
          closeDrawer={() => {
            setDrawer((s) => ({ ...s, open: true }));
            setDrawerSponsor({ open: false, data: {}, idx: "", check: true });
          }}
          title="Add new speaker"
          sub="Enter speaker details"
          width={drawerSponsor?.check ? "490px" : "900px"}
        >
          <AddSponsor
            idx={drawerSponsor.idx}
            data={drawerSponsor.data}
            check={drawerSponsor.check}
            // ...
            isReadOnly={isReadOnly}
            onEditSponsor={onAddSponsor}
            onFetchSponsor={onFetchSponsor}
            // ...
            isLoading={isLoading}
          />
        </EventEntityDrawer>
      </Container>

      <EventActions
        save={{
          isActive: true,
          label: "Save stage",
          isLoading,
          handler: onEditStage,
        }}
      />
    </Page>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(EventStageItem);
