import React from "react";
import { Redirect } from "react-router-dom";

import Guard from "../guard";
import Layout from "../layout";
import EventAttendees from "../views/EventAttendees";
import EventAuth from "../views/EventAuth";
import EventBadgeItem from "../views/EventBadgeItem";
import EventBadges from "../views/EventBadges";
import EventEmails from "../views/EventEmails";
import EventLive from "../views/EventLive";
import EventSetup from "../views/EventSetup";
import EventSpeakers from "../views/EventSpeakers";
import EventSponsors from "../views/EventSponsors";
import EventStageItem from "../views/EventStageItem";
import EventStages from "../views/EventStages";
import EventTicketItem from "../views/EventTicketItem";
import EventTickets from "../views/EventTickets";
// ...
import { PATH_EVENT } from "./paths";

const routes = {
  guard: Guard,
  layout: Layout,
  routes: [
    {
      exact: true,
      path: PATH_EVENT.root,
      component: EventAuth,
    },
    {
      exact: true,
      path: PATH_EVENT.setup,
      component: EventSetup,
    },
    {
      exact: true,
      path: PATH_EVENT.stages,
      component: EventStages,
    },
    {
      exact: true,
      path: PATH_EVENT.stageItem,
      component: EventStageItem,
    },
    {
      exact: true,
      path: PATH_EVENT.speakers,
      component: EventSpeakers,
    },
    {
      exact: true,
      path: PATH_EVENT.sponsors,
      component: EventSponsors,
    },
    {
      exact: true,
      path: PATH_EVENT.attendees,
      component: EventAttendees,
    },
    {
      exact: true,
      path: PATH_EVENT.emails,
      component: EventEmails,
    },
    {
      exact: true,
      path: PATH_EVENT.tickets,
      component: EventTickets,
    },
    {
      exact: true,
      path: PATH_EVENT.ticketItem,
      component: EventTicketItem,
    },
    {
      exact: true,
      path: PATH_EVENT.badges,
      component: EventBadges,
    },
    {
      exact: true,
      path: PATH_EVENT.badgeItem,
      component: EventBadgeItem,
    },
    {
      exact: true,
      path: PATH_EVENT.live,
      component: EventLive,
    },
    {
      component: () => <Redirect to={PATH_EVENT.setup} />,
    },
  ],
};

export default routes;
