import { Box } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import React from "react";

import { dateToISO } from "../../../utils/dates";

const EntryDateButton = ({
  anchorEl,
  setAnchorEl,
  value,
  minDate = new Date(),
  disableDates,
  // ...
  onChangeDate,
  children,
}) => {
  const inOnChange = (date) => {
    if (disableDates.indexOf(dateToISO(date)) === -1) {
      onChangeDate(date);
      setAnchorEl(null);
    }
  };
  // ...
  return (
    <div className="EntryDateButton">
      <Box className="EntryDateButton__trigger">
        {/* <div onClick={(e) => setAnchorEl(e.currentTarget)}>{children}</div> */}
        <div>{children}</div>
      </Box>

      <Popover
        className="EntryDateButtonPopover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        // ...
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="EntryDateButton__picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              // ...
              value={value || null}
              minDate={minDate}
              // ...
              onChange={(d) => inOnChange(d)}
            />
          </LocalizationProvider>
        </div>
      </Popover>
    </div>
  );
};

export default EntryDateButton;
