import React from "react";

const Preview = ({ setPreviewModal }) => {
  return (
    <div className="BadgeStep __preview">
      <img
        className="__illustration"
        alt=""
        src="/static/illustrations/ticketsReview.svg"
      />
      <h3 className="__title">Hooray !</h3>
      <h3 className="__desc">
        Your ticket is generated{" "}
        <span onClick={() => setPreviewModal(true)}>
          click here to review it
        </span>
      </h3>
    </div>
  );
};

export default Preview;
