import { Grid } from "@material-ui/core";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withFormik } from "formik";
import React, { useEffect } from "react";

import { EntryFile, EntryText } from "../../../components/Entry";
import EventActions from "../../../components/EventActions";
import EventForm from "../../../components/EventForm";
import Page from "../../../components/Page";
import useEvent from "../../../hooks/useEvent";
import useFile from "../../../hooks/useFile";
import { valSchemaBrand as validationSchema } from "../entries";
const iniValues = validationSchema.cast();

const EventBranding = ({ setCurrTab, onBackHandler, ...props }) => {
  // const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { isLoading: isLE, data, onUpdate } = useEvent();
  const { isLoading: isLF, onUpload } = useFile();
  // ...
  const isLoading = isLE || isLF;
  // ...
  const onSaveData = async () => {
    if (isLoading) return;
    // ...
    const res = await onUpdate(props.values);
    if (res.error) return;
    setCurrTab((s) => s + 1);
  };
  // ...
  const onUploadFile = async (key, files) => {
    const data = await onUpload(files);
    const ids = data?.map((d) => d.id);
    // ...
    await onUpdate({ ...props.values, [key]: ids[0] });
  };
  // ...
  useEffect(() => {
    if (isLoading) return;
    const isFree = data?.isFree ? "free" : "paid";
    props.resetForm({ values: { ...iniValues, ...data, isFree } });
  }, [data]);
  // ...
  return (
    <Page title="Event Branding | Evey">
      <Grid container spacing={8}>
        <EventForm
          item
          xs={12}
          md={6}
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <EntryFile
            required
            preview
            previewStyle={{ height: "150px" }}
            label="Event logo 300 x 300 px"
            placeholder="Select event logo"
            name="card"
            md={12}
            onUpload={onUploadFile}
            sx={{ margin: "0 0 40px 0" }}
          />
          <EntryFile
            required
            preview
            label="Event banned 300 x 300 px"
            placeholder="Select event banner"
            name="cover"
            md={12}
            onUpload={onUploadFile}
          />
        </EventForm>
        <EventForm item xs={12} md={6} spacing={0} sx={{ height: "440px" }}>
          <EntryText
            label="LinkedIn"
            name="linkedin"
            placeholder="LinkedIn"
            md={12}
          />
          <EntryText
            label=" Facebook event"
            name="facebook"
            placeholder="Facebook"
            md={12}
          />
          <EntryText
            label="Event teasing video"
            name="videoCover"
            placeholder="Youtube"
            md={12}
          />
          <EntryText
            label="Website"
            name="website"
            placeholder="https://"
            md={12}
          />
        </EventForm>
      </Grid>

      <EventActions
        save={{
          isActive: true,
          label: "Save & continue",
          isLoading,
          handler: onSaveData,
        }}
        cancel={{
          isActive: true,
          label: "Back",
          handler: onBackHandler,
        }}
      />
    </Page>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(EventBranding);
