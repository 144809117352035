import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "STAGE", default: {} });

const getStageData = (e) => ({
  label: e.label,
  description: e.description,
  requireVisio: e.requireVisio,
  // ...
  cover: e.cover,
  card: e.card,
});

export default function useStage(id) {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const onUpdate = useRecoilCallback(() => async (stage) => {
    setIsLoading(true);
    setHasError(false);

    try {
      let stageData = getStageData(stage);
      // ...
      if (id) stageData.id = parseInt(id, 10);
      // ...
      let res = await HTTP.POST(
        `/organizations/events/${THE_ID}/updateStage`,
        stageData
      );
      // ...
      enqueueSnackbar("Stage updated", { variant: "success" });
      // ...
      setAtom({ ...data, ...res });
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let data = await HTTP.POST(
          `/organizations/events/${THE_ID}/updateStage`,
          { id: parseInt(id, 10) }
        );
        // ...
        setAtom(data || {});
      } catch (err) {
        enqueueSnackbar("Error occured", { variant: "error" });
      }
    }
    // ...
    fetchMyAPI();
  }, []);
  // ...
  return { isLoading, hasError, data, onUpdate };
}
