import * as yup from "yup";

import { dateSameToISO } from "../../utils/dates";

export const TABLE_HEAD = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email address",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Full name",
  },
  {
    id: "inviteDateLabel",
    numeric: false,
    disablePadding: true,
    label: "Invitation date",
  },
  {
    id: "statusLabel",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
];

export const validationSchema = yup.object({
  email: yup.string().required().default(""),
  name: yup.string().notRequired().default(""),
  // invitationStatus: yup.string().required().default("invited"),
  // ...
  // custom: yup.object().required().default({}),
  // inviteDate: yup.string().required().default(dateSameToISO(new Date())),
});
