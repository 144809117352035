import "./style.scss";

import { Button } from "@material-ui/core";
import Loader from "@material-ui/core/CircularProgress";
import { withFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import HTTP from "../../api/http";
import { EntryText } from "../../components/Entry";
import EventForm from "../../components/EventForm";
import SVG from "../../components/Svg";
import useQuery from "../../hooks/useQuery";
// ...
import {
  getNewDates,
  stupidSlugify,
  valSchemaInfo as validationSchema,
} from "./entries";
const iniValues = validationSchema.cast();

const EventAuth = (props) => {
  const query = useQuery();
  const history = useHistory();
  const [isNew, setIsNew] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const onStartHandler = async () => {
    setIsLoading(true);
    // ...
    const THE_JWT = query.get("token");
    window.localStorage.setItem("THE_JWT", THE_JWT);
    // ...
    const title = props.values?.title || "";
    const slug = stupidSlugify(title);
    const { startDate, endDate } = getNewDates();
    // ...
    const data = {
      title,
      slug,
      startDate,
      endDate,
      // ...
      // access: "public",
      category: "event",
      type: "virtual",
    };
    // ...
    try {
      //# Create new event
      let res = await HTTP.POST("/organizations/events/create", data);
      window.localStorage.setItem("THE_ID", res.id);
      // ...
      setIsLoading(false);
      history.push("/event-setup");
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar("Error occured", { variant: "error" });
      console.error("#CreateNewEvent :: ", err);
    }
  };
  // ...
  const preAuth = async () => {
    const NEW_IS = query.get("eventNew");
    const NEW_ID = query.get("eventId");
    const NEW_JWT = query.get("token");
    // ...
    const OLD_ID = window.localStorage.getItem("THE_ID");
    const OLD_JWT = window.localStorage.getItem("THE_JWT");
    // ...
    let THE_ID = NEW_ID || OLD_ID;
    let THE_JWT = NEW_JWT || OLD_JWT;

    //# NewEvent
    setIsNew(NEW_IS);
    if (NEW_IS) return;
    // ...
    if (THE_ID && THE_JWT) {
      window.localStorage.setItem("THE_ID", THE_ID);
      window.localStorage.setItem("THE_JWT", THE_JWT);
      // ...
      history.push("/event-setup");
    } else {
      const LIVE_URL = process.env.REACT_APP_EVEY_URL;
      window.location.href = `${LIVE_URL}/auth/login`;
    }
  };
  // ...
  useEffect(preAuth, []);
  // ...
  return (
    <div className="EventAuth">
      <div className="EventAuth__inner">
        <div className="EventAuth__content">
          <div className="EventAuth__content__inner">
            <div className="__top">
              <span className="__top__icon">
                <span className="__inner" />
              </span>
              <span style={{ color: "#fff", margin: "0 7px 0 0" }}>10000+</span>
              <span>event participants are watching</span>
            </div>
            <div className="__title">
              A seemless
              <div>
                Event <span style={{ color: "#00AB55" }}>Experience</span>
              </div>
            </div>
            <div className="__list">
              <div className="__list__item">
                <span className="__num">1</span>
                Build and publish your event in minutes
              </div>
              <div className="__list__item">
                <span className="__num">2</span>
                Explore real time event analytics and more
              </div>
            </div>

            {isNew && (
              <div className="__new">
                <div className="__new__title">Add your event name</div>
                <EventForm style={{ width: "500px" }}>
                  <EntryText
                    required
                    label="Event name"
                    name="title"
                    placeholder="Event name"
                    xs={12}
                    md={12}
                  />
                </EventForm>
              </div>
            )}

            <div className="__actions">
              <Button
                variant="contained"
                onClick={onStartHandler}
                disabled={isNew && !props.values?.title}
                sx={{ width: "207px", height: "65px" }}
              >
                {isLoading && <Loader style={{ color: "#fff" }} />}
                {!isLoading && <span>Get Started Now</span>}
              </Button>
              {/* <Button
                variant="outlined"
                sx={{ width: "207px", height: "65px" }}
              >
                View A Demo
              </Button> */}
            </div>
          </div>
        </div>
        <div className="EventAuth__preview">
          <SVG src="/static/auth.svg" />
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(EventAuth);
