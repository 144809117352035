import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "BADGE", default: {} });

// # Sanitize Data
const getBadgeData = (b) => ({
  id: b.id,
  // ...
  title: b.title,
  category: b.category,
  // ..
  textColor: b.textColor,
  backgroundColor: b.backgroundColor,
  backgroundImage: b.backgroundImage,
  // ...
  text: b.text,
  subText1: b.subText1,
  subText2: b.subText2,
  subText3: b.subText3,
});

export default function useEvent(id) {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async () => {
    try {
      const data = await HTTP.POST(
        `/organizations/events/${THE_ID}/updateBadgeSetting`,
        { id }
      );
      // ...
      setAtom(data || {});
    } catch (err) {
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  };
  // ...
  const onUpdate = useRecoilCallback(() => async (raw) => {
    setIsLoading(true);
    setHasError(false);

    const badge = getBadgeData(raw);

    try {
      await HTTP.POST(
        `/organizations/events/${THE_ID}/updateBadgeSetting`,
        badge
      );
      // ...
      enqueueSnackbar("Badge updated", { variant: "success" });
      // ...
      getAll();
      // ...
      return {};
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onUpdate,
  };
}
