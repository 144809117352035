import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import React, { useState } from "react";

const Category = ({ val }) => {
  return (
    <span>
      {val === "registration" && "Registration email"}
      {val === "invitation" && "Invitation email"}
      {val === "speaker" && "Speaker email"}
    </span>
  );
};

export default Category;
