import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "TICKETS", default: [] });

// # Sanitize data
const getTicketData = () => ({});

export default function useEvent() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async () => {
    try {
      const tickets = await HTTP.GET(
        `/organizations/events/${THE_ID}/getTicketSettings`
      );
      // ...
      setAtom(tickets || []);
    } catch (err) {
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  };
  // ...
  const onCreate = useRecoilCallback(() => async () => {
    setIsLoading(true);
    setHasError(false);

    let ticketData = getTicketData();

    try {
      const res = await HTTP.POST(
        `/organizations/events/${THE_ID}/updateTicketSetting`,
        ticketData
      );
      // ...
      enqueueSnackbar("Ticket added", { variant: "success" });
      // ...
      return { ticketId: res.id };
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  });
  const onDelete = useRecoilCallback(() => async (ticketId) => {
    setIsLoading(true);
    setHasError(false);

    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/deleteTicketSetting`, {
        ids: [ticketId],
      });
      // ...
      enqueueSnackbar("Ticket deleted", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onCreate,
    onDelete,
  };
}
