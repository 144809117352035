import { useFormikContext } from "formik";
import React from "react";

import { EntryFile, EntrySelect, EntryText } from "../../../components/Entry";
import EventForm from "../../../components/EventForm";
import useFile from "../../../hooks/useFile";

const Details = () => {
  const { onUpload } = useFile();
  const { values, setFieldValue } = useFormikContext();
  // ...
  const onUploadFile = async (key, files) => {
    // const data = await onUpload(files);
    // setFieldValue([key], data[0]);
  };
  // ...
  return (
    <div className="BadgeStep __details">
      <h3 className="BadgeStep__title">
        Create printable badge for your attendees
      </h3>
      <div className="BadgeStep__groups">
        <div className="__group">
          <h3 className="__group__title">Badge label</h3>
          <EventForm sx={{ mb: "60px" }}>
            <EntryText label="Badge name" name="title" xs={12} md={12} />
          </EventForm>
        </div>
        <div className="__sep" />
        <div className="__group">
          <h3 className="__group__title">Choose a badge category</h3>
          <EventForm sx={{ mb: "60px" }}>
            <EntrySelect
              xs={12}
              md={12}
              label="Badge categories"
              name="category"
              options={[
                { label: "Attendees", value: "attendees" },
                { label: "Speakers", value: "speakers" },
                { label: "Sponsors", value: "sponsors" },
                { label: "CSV", value: "csv" },
              ]}
            />
            {values?.category === "csv" && (
              <EntryFile
                label="Upload list (.CSV)"
                name="banner"
                placeholder="Select local file"
                onUpload={onUploadFile}
                xs={12}
                md={12}
                sx={{ marginTop: "20px" }}
              />
            )}
          </EventForm>
        </div>
      </div>
    </div>
  );
};

export default Details;
