import "./style.scss";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { withFormik } from "formik";
import React, { useEffect } from "react";
import Moment from "react-moment";

import { EntrySelect, EntryText } from "../../../../components/Entry";
import EventButton from "../../../../components/EventButton";
import useFile from "../../../../hooks/useFile";
// ...
import { validationSchema } from "./entries";
const iniValues = validationSchema.cast();

const AddEmail = ({
  idx,
  data = {},
  onEditEmail,
  isLoading: isLT,
  ...props
}) => {
  const { isLoading: isLF, onUpload } = useFile();
  const isLoading = isLT || isLF;
  // ...
  const values = props?.values || {};
  const category = values?.category || "";
  // ...
  const onUploadFile = async (e) => {
    const files = e.target.files;
    if (files?.length === 0) return;
    // ...
    const data = await onUpload(files);
    props.setFieldValue("files", data);
  };
  // ...
  useEffect(() => {
    props.resetForm({ values: { ...iniValues, ...data } });
  }, []);
  // ...
  return (
    <div className="AddEmail">
      <div className="AddEmail__header">
        <div className="AddEmail__header__top">
          <div className="__left">
            {category === "speaker" && "Speaker template"}
            {category === "invitation" && "Invitation template"}
            {category === "registration" && "Registration template"}
          </div>
          <div className="__right">
            <EntrySelect
              name="category"
              options={[
                { label: "Speaker template", value: "speaker" },
                { label: "Invitation template", value: "invitation" },
                { label: "Registration template", value: "registration" },
              ]}
              xs={12}
              md={12}
            />
          </div>
        </div>
        <div className="AddEmail__header__subject">
          <EntryText
            name="subject"
            placeholder="Email subject"
            sx={12}
            md={12}
          />
        </div>
      </div>
      <div className="AddEmail__wsysg">
        {/* <div className="AddEmail__wsysg__header" /> */}
        <div className="AddEmail__wsysg__input">
          <EntryText
            name="content"
            placeholder="Email body"
            multiline
            sx={12}
            md={12}
          />
        </div>
        <div className="AddEmail__wsysg__footer">
          <div className="__title">User information placeholders</div>
          <div className="__tags">
            <div className="__tag">[FullName]</div>
            <div className="__tag">[Organization]</div>
            <div className="__tag">[Position]</div>
          </div>
        </div>
        <div className="AddEmail__files">
          {values.files?.map((f, i) => (
            <div className="__item" key={i}>
              <div className="__item__icon">
                <InsertDriveFileIcon />
              </div>
              <div className="__item__details">
                <div className="__item__details__name">{f.name}</div>
                <div className="__item__details__date">
                  <Moment format="D MMM YYYY">{f.created_at}</Moment>
                </div>
              </div>
              <div className="__item__delete">
                <HighlightOffIcon
                  style={{ color: "#FF4842" }}
                  onClick={() => props.setFieldValue("files", [])}
                />
              </div>
            </div>
          ))}

          <div className="AddEmail__files__add">
            <input id="email-file-input" type="file" onChange={onUploadFile} />
            <label htmlFor="email-file-input">
              <span>Add file</span>
              <img src="/static/icons/file_icon.svg" alt="" />
            </label>
          </div>
        </div>
      </div>
      <div className="AddEmail__wsysg__actions">
        <EventButton
          isLoading={isLoading}
          handler={() => onEditEmail(props.values, idx)}
        >
          Save email
        </EventButton>
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(AddEmail);
