import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "TICKET", default: {} });

// # Sanitize Data
const getTicketData = (t) => ({
  id: t.id,
  // ...
  title: t.title,
  isFree: t.isFree,
  isActive: t.isActive,
  price: t.price,
  currency: t.currency,
  // ...
  conditionKey: t.conditionKey,
  conditionValue: t.conditionValue,
  // ...
  textColor: t.textColor,
  backgroundColor: t.backgroundColor,
  // ...
  backgroundImage: t.backgroundImage,
  bannerImage: t.bannerImage,
  // ...
  subHeading1: t.subHeading1,
  subHeading2: t.subHeading2,
  subHeading3: t.subHeading3,
  paragraph: t.paragraph,
  heading: t.heading,
  text: t.text,
  subText1: t.subText1,
  subText2: t.subText2,
  subText3: t.subText3,
});

export default function useEvent(id) {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async () => {
    try {
      const data = await HTTP.POST(
        `/organizations/events/${THE_ID}/updateTicketSetting`,
        { id }
      );
      setAtom(data || {});
    } catch (err) {
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  };
  // ...
  const onUpdate = useRecoilCallback(() => async (ticket) => {
    setIsLoading(true);
    setHasError(false);

    let ticketData = getTicketData(ticket);
    if (!ticketData?.price || ticketData?.isFree) ticketData.price = 0;

    try {
      await HTTP.POST(
        `/organizations/events/${THE_ID}/updateTicketSetting`,
        ticketData
      );
      // ...
      enqueueSnackbar("Ticket updated", { variant: "success" });
      // ...
      getAll();
      // ...
      return {};
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onUpdate,
  };
}
