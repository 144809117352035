import "./style.scss";

import { experimentalStyled as styled } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";

import useLayout from "../hooks/useLayout";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";

const MainLayout = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  overflow: "hidden",
}));

const AppContent = styled("div")(() => ({
  position: "relative",
  display: "flex",
}));

const AppMain = styled("div")(({ theme }) => ({
  height: "calc(100vh - 64px)",
  width: "100%",
  padding: "20px 0 130px 0",
  overflowX: "hidden",
  overflowY: "auto",
  [theme.breakpoints.up("lg")]: {
    height: "calc(100vh - 92px)",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

export default function DashboardLayout({ children }) {
  const isAuth = useLayout();
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  // ...
  useEffect(() => {
    ref?.current?.scrollTo(0, 0);
  }, [children]);
  // ...
  if (isAuth) return <>{children}</>;
  // ...
  return (
    <MainLayout>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <AppContent>
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
        <AppMain ref={ref} className="scrollbar">
          {children}
        </AppMain>
      </AppContent>
    </MainLayout>
  );
}
