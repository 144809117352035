import "./style.scss";

import { Box, Container } from "@material-ui/core";
import React from "react";

import EventButton from "../EventButton";

const EventActions = ({ save, cancel }) => {
  // ...
  return (
    <Box className="EventActions">
      <Container>
        <Box className="EventActions__inner">
          {cancel && cancel.isActive && (
            <EventButton
              variant="back"
              className="__cancel"
              isLoading={cancel.isLoading}
              handler={cancel.handler}
              // ...
              sx={{ backgroundColor: "red" }}
            >
              {cancel.label}
            </EventButton>
          )}

          {save && save.isActive && (
            <EventButton
              className="__save"
              isLoading={save.isLoading}
              handler={save.handler}
              disabled={save.isDisabled}
            >
              {save.label}
            </EventButton>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default EventActions;
