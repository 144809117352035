import { experimentalStyled as styled } from "@material-ui/core/styles";
import { withFormik } from "formik";
import React, { useEffect } from "react";

import { EntryDate, EntrySelect, EntryTime } from "../Entry";
import EventButton from "../EventButton";
import EventForm from "../EventForm";
import { validationSchema } from "./entries";
const iniValues = validationSchema.cast();

const LabelStyle = styled("div")(() => ({
  color: "#333",
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "400",
  margin: "60px 0 30px 0",
}));

const AddEmailSettings = ({ onSetEmailSettings, data = {}, ...props }) => {
  // ...
  useEffect(() => {
    // props.resetForm({ values: { ...iniValues, ...data } });
  }, [data]);
  // ...
  return (
    <>
      <LabelStyle>Send invitation on:</LabelStyle>
      <EventForm>
        <EntryDate label="Date" name="invite" />
        <EntryTime label="Time" name="invite" />
      </EventForm>

      <LabelStyle>Send Reminders on:</LabelStyle>
      <EventForm>
        <EntryDate label="Date" name="remind" />
        <EntryTime label="Time" name="remind" />
      </EventForm>

      <LabelStyle>Choose or create an invitation email:</LabelStyle>
      <EventForm>
        <EntrySelect
          label="Email template"
          name="template"
          options={[{ label: "Default template", value: "default" }]}
          xs={12}
          md={12}
        />
      </EventForm>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "50px 0 0 0",
        }}
      >
        <EventButton handler={() => onSetEmailSettings(props.values)}>
          Save
        </EventButton>
      </div>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(AddEmailSettings);
