import "./style.scss";

import { Box, Typography } from "@material-ui/core";
import React from "react";
import Moment from "react-moment";

import SVG from "../Svg";

const EventBanner = ({ data }) => {
  const coverUrl = data?.cover?.[0]?.url;
  // ...
  return (
    <Box className="EventBanner">
      <Box className="__inner">
        <Box className="__preview">
          {coverUrl && <img src={coverUrl} alt="Event Banner" />}
        </Box>
        <Box className="__info">
          <Box className="__info__inner">
            <Box className="__info__details">
              <div>
                <Typography variant="sub" component="h1" className="__org">
                  <div className="__org__img">
                    {data?.organization?.logo?.url && (
                      <img
                        src={data.organization.logo.url}
                        alt="Event Banner"
                        layout="fill"
                        quality={100}
                      />
                    )}
                  </div>
                  <span>{data?.organization?.libele}</span>
                </Typography>
                <Typography
                  variant="titleBig"
                  component="h1"
                  className="__title"
                >
                  {data?.title}
                </Typography>
              </div>
              <div>
                <Typography variant="title" component="div" className="__date">
                  <span className="__icon">
                    <SVG src="/static/setup/review/cal.svg" />
                  </span>
                  <span>
                    <Moment format="MMMM D, YYYY">{data?.startDate}</Moment>
                    {" at "}
                    <Moment format="hh:mm a">{data?.startDate}</Moment>
                  </span>
                </Typography>
                <Typography
                  variant="title"
                  component="div"
                  className="__location"
                >
                  <span className="__icon">
                    <SVG src="/static/setup/review/loc.svg" />{" "}
                  </span>
                  <span>{data?.address || "No location added"}</span>
                </Typography>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EventBanner;
