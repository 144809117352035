import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";

const DotsMenu = ({ options = [] }) => {
  const [open, setOpen] = useState(null);
  // ...
  return (
    <Box>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(e) => setOpen(e.currentTarget)}
        style={{ padding: "0", margin: "0" }}
      >
        <MoreVertIcon style={{ fontSize: "22px" }} />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={open}
        onClose={() => setOpen(null)}
        open={Boolean(open)}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((opt) => (
          <MenuItem
            key={opt.label}
            onClick={() => {
              opt.handler();
              setOpen(null);
            }}
            sx={{ color: opt.isDanger ? "red" : "" }}
          >
            {opt.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default DotsMenu;
