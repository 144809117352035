import "./style.scss";

import Dialog from "@material-ui/core/Dialog";
import { useFormikContext } from "formik";
import React from "react";

import EventButton from "../../../../components/EventButton";

const TicketPreview = ({ open, setModal }) => {
  const { values } = useFormikContext();
  // ...
  const {
    textColor,
    backgroundColor = "#fff",
    backgroundImage,
    bannerImage,
    // ...
    heading,
    subHeading1,
    subHeading2,
    subHeading3,
    paragraph,
    // ...
    text,
    subText1,
    subText2,
    subText3,
  } = values || {};
  // ...
  return (
    <Dialog
      className="TicketPreviewDialog"
      open={open}
      onClose={() => setModal(false)}
      maxWidth={false}
    >
      <div className="TicketPreview">
        <div className="TicketPreview__header">
          <p className="TicketPreview__header__title">Ticket preview</p>
          <EventButton
            handler={() => setModal(false)}
            sx={{ backgroundColor: "red" }}
          >
            Exit Preview
          </EventButton>
        </div>
        <div
          className="TicketPreview__content scrollbar"
          style={{
            color: textColor,
            backgroundColor,
            backgroundImage: `url(${backgroundImage?.url})`,
            backgroundSize: "cover",
          }}
        >
          <div className="__preview">
            <div className="__preview__inner">
              <img
                src={
                  bannerImage?.url || "/static/mock-images/tmps/ticket.tmp.svg"
                }
              />
            </div>
          </div>

          <div className="__group __top">
            <div className="__side __org">
              <div className="__title">{heading}</div>
              <div className="__name">{subHeading3}</div>
              <div className="__date">{subHeading1}</div>
              <div className="__other">{subHeading2}</div>
            </div>
            <div className="__side __info">
              <p>{paragraph}</p>
            </div>
          </div>
          <div className="__group __bottom">
            <div className="__side __user">
              <div className="__info">
                <div className="__info__num">
                  <span className="__label">Ticket3 </span>
                  <span className="__value">#58413</span>
                </div>
                <div className="__info__name">{text}</div>
                <div className="__info__pos">{subText2}</div>
                <div className="__info__org">{subText1}</div>
                <div className="__info__other">
                  Ticket purshased on December 8th 2021 at 2:00PM via email:
                </div>
                <div className="__info__email">{subText3}</div>
              </div>
            </div>
            <div className="__side __qr">
              <div className="__qr">
                <img src="/static/icons/qr.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TicketPreview;
