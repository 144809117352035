import { PATH_EVENT } from "../routes/paths";

const sidebarConfig = [
  {
    subheader: "BUILD YOUR EVENT",
    items: [
      {
        title: "Event setup",
        href: PATH_EVENT.setup,
        icon: "/static/setup/nav/setup.svg",
      },
      {
        title: "Stages & schedules",
        href: PATH_EVENT.stages,
        icon: "/static/setup/nav/stages.svg",
      },
      {
        title: "Manage speakers",
        href: PATH_EVENT.speakers,
        icon: "/static/setup/nav/speakers.svg",
      },
      {
        title: "Sponsors & partners",
        href: PATH_EVENT.sponsors,
        icon: "/static/setup/nav/sponsors.svg",
      },
    ],
  },
  {
    subheader: "CONNECT WITH YOUR AUDIENCE",
    items: [
      {
        title: "Event Attendees",
        href: PATH_EVENT.attendees,
        icon: "/static/setup/nav/attendees.svg",
      },
      {
        title: "Email templates",
        href: PATH_EVENT.emails,
        icon: "/static/setup/nav/emails.svg",
      },
      {
        title: "Ticketing",
        href: PATH_EVENT.tickets,
        icon: "/static/setup/nav/tickets.svg",
      },
      {
        title: "Badging",
        href: PATH_EVENT.badges,
        icon: "/static/setup/nav/badges.svg",
      },
    ],
  },
  {
    subheader: "GET EVENT INSIGHTS",
    items: [
      {
        title: "Live insights",
        href: PATH_EVENT.live,
        icon: "/static/setup/nav/dashboard.svg",
      },
    ],
  },
];

export default sidebarConfig;
