import * as yup from "yup";

import { dateToID } from "../../utils/dates";

export const TABLE_HEAD = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Slot name",
  },
  {
    id: "startTime",
    numeric: false,
    disablePadding: true,
    label: "Start time",
  },
  {
    id: "durationLabel",
    numeric: false,
    disablePadding: true,
    label: "Duration",
  },
  {
    id: "speakerId",
    numeric: false,
    disablePadding: true,
    label: "Speaker",
  },
];

export const stageSchema = yup.object({
  label: yup.string().required().default(""),
  description: yup.string().default(""),
  // ...
  cover: yup.array().notRequired().default([]),
  card: yup.object().notRequired().default({}),
  // ...
  requireVisio: yup.boolean().default(false),
});

export const addSlotSchema = yup.object({
  title: yup.string().required().default(""),
  description: yup.string().required().default(""),
  start: yup.date().required().default(new Date()),
  duration: yup.string().required().default("60"),
  // ...
  speakers: yup.array().required().default([]),
  sponsors: yup.array().required().default([]),
});

export const getDatesFromSlots = (slots = []) => {
  let dateSet = new Set(slots.map((s) => dateToID(s.start)));
  return Array.from(dateSet)?.sort((a, b) => (a > b ? 1 : -1)) || [];
};
