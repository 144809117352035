import "./style.scss";

import { Box, Container } from "@material-ui/core";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import EventActions from "../../components/EventActions";
import EventSectionTitle from "../../components/EventSectionTitle";
import EventStepper from "../../components/EventStepper";
import EventTabPanel from "../../components/EventTabPanel";
// ...
import Page from "../../components/Page";
import useRegFields from "../../hooks/useRegFields";
import useTicket from "../../hooks/useTicket";
import Preview from "./components/Preview";
// ...
import { TABS_LIST } from "./config";
import { getTicketData, validationSchema } from "./entries";
const iniValues = validationSchema.cast();

const EventTicketIem = ({ ...props }) => {
  // const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { id: ticketId } = useParams();
  // ...
  const { data: regFields } = useRegFields();
  const { isLoading, data, onUpdate } = useTicket(ticketId);
  const [previewModal, setPreviewModal] = useState(false);
  const [currTab, setCurrTab] = useState(0);
  const isPublishStep = currTab === 2;
  // ...
  const onEditTicket = async () => {
    const { error } = await onUpdate(props.values);
    // ...
    if (error) return;
    if (!isPublishStep) {
      setCurrTab((s) => s + 1);
    }
  };
  // ...
  useEffect(() => {
    props.resetForm({ values: { ...iniValues, ...getTicketData(data) } });
  }, [data]);
  // ...
  return (
    <Page title="Ticketing">
      <Container>
        <Box>
          <EventSectionTitle
            title="Ticketing"
            subTitle="Create and manage event tickets"
            src="/static/setup/title/tickets.svg"
          />

          <EventStepper
            currTab={currTab}
            setCurrTab={(step) => setCurrTab(step)}
            ITEMS={TABS_LIST}
          />

          {TABS_LIST.map((tab, idx) => (
            <EventTabPanel
              key={idx}
              value={idx}
              currTab={currTab}
              sx={{ mt: "30px" }}
            >
              <tab.Node
                registrationFields={regFields}
                setPreviewModal={setPreviewModal}
              />
            </EventTabPanel>
          ))}
        </Box>
      </Container>

      <Preview open={previewModal} setModal={setPreviewModal} />

      <EventActions
        save={{
          isActive: true,
          label: isPublishStep ? "Save & publish" : "Save & continue",
          isLoading,
          handler: onEditTicket,
        }}
        cancel={{
          isActive: 0 < currTab,
          label: "Back",
          handler: () => setCurrTab((s) => s - 1),
        }}
      />
    </Page>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(EventTicketIem);
