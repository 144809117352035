// ...
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
// ...
import { jssPreset, StylesProvider, useTheme } from "@material-ui/core/styles";
import { create } from "jss";
// ...
import rtl from "jss-rtl";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import rtlPlugin from "stylis-plugin-rtl";

RtlLayout.propTypes = {
  children: PropTypes.node,
};

export default function RtlLayout({ children }) {
  const theme = useTheme();

  const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
  });

  useEffect(() => {
    document.dir = theme.direction;
  }, [theme.direction]);

  const cache = createCache({
    key: theme.direction === "rtl" ? "rtl" : "css",
    prepend: true,
    stylisPlugins: theme.direction === "rtl" ? [rtlPlugin] : [],
  });

  cache.compat = true;

  return (
    <CacheProvider value={cache}>
      <StylesProvider jss={jss}>{children}</StylesProvider>
    </CacheProvider>
  );
}
