import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "EVENT", default: {} });

const getEventData = (e) => ({
  title: e.title,
  slug: e.slug,
  address: e.address,
  startDate: e.startDate,
  endDate: e.endDate,
  shortDescription: e.shortDescription,
  description: e.description,
  type: e.type,
  category: e.category,
  tags: e.tags,
  requireVisio: e.requireVisio,
  // ...
  isFree: e?.isFree === "free",
  // ...
  cover: e.cover,
  card: e.card,
  linkedin: e.linkedin,
  facebook: e.facebook,
  videoCover: e.videoCover,
  website: e.website,
  // ...
  access: e.access,
  password: e.password,
  regLabel: e.regLabel,
  regFields: e.regFields,
  // ...
  published: e.published,
});

export default function useEvent() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async () => {
    try {
      const event = await HTTP.GET(`/organizations/events/${THE_ID}`);
      setAtom({ ...event });
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error", persist: true });
    }
  };
  // ...
  const onUpdate = useRecoilCallback(() => async (dataSate) => {
    setIsLoading(true);
    setHasError(false);

    try {
      // # Update Event
      let eventData = getEventData(dataSate);
      delete eventData.registration;
      await HTTP.POST(`/organizations/events/${THE_ID}/update`, eventData);
      // ...
      getAll();
      // ...
      enqueueSnackbar("Event updated", { variant: "success" });
      return {};
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onUpdate,
  };
}
