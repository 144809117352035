import NProgress from "nprogress";
import { useEffect, useState } from "react";
import {
  atom,
  useRecoilCallback,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "STAGE_SLOTS", default: [] });

const getSlotData = (s) => ({
  slug: s.slug,
  title: s.title,
  description: s.description,
  start: s.start,
  duration: s.duration,
  card: s.card,
  cover: s.cover,
  streamConf: s.streamConf,
  // ...
  speakers: [],
  sponsors: s?.sponsors?.map((si) => si?.value) || [],
});

export default function useEvent(stageId) {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ..
  const getAll = async () => {
    const slots = await HTTP.GET(
      `/organizations/events/${THE_ID}/getStageSessions/${stageId}`
    );
    // ...
    setAtom([...slots]);
  };
  // ...

  const onUpdateDates = useRecoilCallback(() => async (slotsList) => {
    setIsLoading(true);
    setHasError(false);

    // # Save slot
    for (const slot of slotsList) {
      await HTTP.POST(`/organizations/events/${THE_ID}/updateSession`, {
        ...slot,
        stage: parseInt(stageId),
      });
    }

    // # Get Stage Slots
    getAll();
  });
  const onUpdate = useRecoilCallback(() => async (raw) => {
    setIsLoading(true);
    setHasError(false);

    // # Save slot
    let slot = getSlotData(raw);
    if (raw.id) slot.id = raw.id;
    // ...
    if (raw?.sponsors?.length > 0)
      slot.sponsors = raw.sponsors.map((s) => s.value);
    // ...
    await HTTP.POST(`/organizations/events/${THE_ID}/updateSession`, {
      ...slot,
      stage: parseInt(stageId),
    });

    // # Get Stage Slots
    getAll();
  });
  const onRemove = useRecoilCallback(() => async (slot) => {
    setIsLoading(true);
    setHasError(false);

    // # Save slot
    await HTTP.POST(`/organizations/events/${THE_ID}/deleteSessions`, {
      stage: parseInt(stageId),
      ids: [slot.id],
    });

    // # Get Stage Slots
    getAll();
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onUpdate,
    onRemove,
    onUpdateDates,
  };
}
