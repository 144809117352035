import axios from "axios";

// USER
// EMAIL : dev@dev.dev
// PASS  : dev123
// ORG   : DEV_ORG

const baseURL = process.env.REACT_APP_API_URL || "https://test.api.evey.live";
const HTTP = axios.create({
  mode: "no-cors",
  baseURL,
  timeout: 60000,
});

const config = () => {
  const jwt = localStorage.getItem("THE_JWT");
  // ...
  if (!jwt) return {};
  // ...
  return {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
};

/**
 * @param URL - The URL to make the request to.
 * @returns The data from the response.
 */
const GET = async (URL) => {
  const res = await HTTP.get(URL, config());
  return res.data;
};

/**
 * @param URL - The URL of the API endpoint you want to call.
 * @param data - the data to be sent to the server
 * @returns The data from the response.
 */
const POST = async (URL, data) => {
  const res = await HTTP.post(URL, data, config());
  return res.data;
};

/**
 * @param URL - The URL of the API endpoint you want to call.
 * @param data - The data to be sent as the request body.
 * @returns The data returned from the server.
 */
const PUT = async (URL, data) => {
  const res = await HTTP.put(URL, data, config());
  return res.data;
};

/**
 * @param URL - The URL of the API endpoint you want to call.
 * @param data - The data to be sent as the request body.
 * @returns The data returned from the server.
 */
const DELETE = async (URL, data) => {
  const res = await HTTP.delete(URL, data, config());
  return res.data;
};

export default { GET, POST, PUT, DELETE };
