import { Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useFormikContext } from "formik";
import React from "react";

// ...
import {
  EVENT_VISIBILITIES_DETAILS,
  REGISTRATION_METHODS_DETAILS,
} from "../entries";
import AccessCode from "./AccessCode";
import FormBuilder from "./FormBuilder";
import RegDetails from "./RegDetails";
// ...
import RegEntries from "./RegEntries";

const RegSection = withStyles((theme) => ({
  root: {
    display: "flex",
    margin: "0 0 135px 0",
    [theme.breakpoints.down("md")]: {
      margin: "0 0 60px 0",
    },
  },
  form: {
    fontFamily: "Montserrat, sans-serif",
    padding: "0 10% 0 0",
    "& .__title": {
      color: "#637381",
      fontSize: "20px",
      lineHeight: "22px",
      fontWeight: "600",
      margin: "0 0 35px 0",
    },
    "& .__entries": {
      margin: "0 0 20px 0",
    },
    "& .__helper": {
      color: "#333333",
      fontSize: "16px",
      lineHeight: "16px",
    },
    "& .__extra": {
      margin: "40px 0 0 0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "0 0 20px 0",
      "& .__title": {
        fontSize: "18px",
        lineHeight: "20px",
        margin: "0 0 16px 0",
      },
      "& .__entries": {
        margin: "0 0 16px 0",
      },
      "& .__helper": {
        color: "#333333",
        fontSize: "13px",
        lineHeight: "15px",
      },
      "& .__extra": {
        margin: "10px 0 0 0",
      },
    },
  },
  details: {},
}))(({ title, helper, entries, classes }) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { values, handleChange, handleBlur } = useFormikContext();

  // - Base
  const name = entries.name;
  const options = entries.options;
  const value = values?.[name];
  const isVis = name === "access";
  const isFields = name === "regLabel";

  // - Details
  const details = isVis
    ? EVENT_VISIBILITIES_DETAILS
    : REGISTRATION_METHODS_DETAILS;
  const currDetails = details?.[value];

  // - Extra
  const isVisRest = value === "restricted";
  const isFieldsRest = value === "customized";
  // ...
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={6} className={classes.form}>
        <Box className="__title">{title}</Box>
        <Box className="__entries">
          <RegEntries
            name={name}
            value={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={options}
          />
        </Box>

        <Box
          className="__helper"
          style={{ marginTop: isMobile ? "20px" : "0" }}
        >
          {helper}
        </Box>

        {isVis && isVisRest && (
          <Box className="__extra">
            <AccessCode />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={6} className={classes.details}>
        <RegDetails {...currDetails} />
      </Grid>

      {isFields && isFieldsRest && (
        <Grid item xs={12} md={12}>
          <FormBuilder />
        </Grid>
      )}
    </Grid>
  );
});

export default RegSection;
