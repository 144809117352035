// import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withFormik } from "formik";
import React, { useEffect } from "react";

import EventActions from "../../../components/EventActions";
import Page from "../../../components/Page";
import useEvent from "../../../hooks/useEvent";
import useRegFields from "../../../hooks/useRegFields";
import RegSection from "../components/RegSection";
import {
  EVENT_VISIBILITIES,
  REGISTRATION_METHODS,
  valSchemaVis as validationSchema,
} from "../entries";
const iniValues = validationSchema.cast();

const EventRegistration = ({ setCurrTab, onBackHandler, ...props }) => {
  // const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { isLoading: isLE, data: dataE, onUpdate: onUE } = useEvent();
  const { isLoading: isLR, data: dataR, onUpdate: onUR } = useRegFields();
  // ...
  const isLoading = isLE || isLR;
  // ...
  const onSaveData = async () => {
    if (isLoading) return;
    // ...
    const values = { ...props.values };
    const { regLabel, regFields } = values;
    // ...
    delete values.regLabel;
    delete values.regFields;
    // ...
    const x = await onUE(values);
    const y = await onUR({ regLabel, regFields });
    // ...
    if (x.error || y.error) return;
    setCurrTab((s) => s + 1);
  };
  // ...
  useEffect(() => {
    if (isLoading) return;
    const isFree = dataE?.isFree ? "free" : "paid";
    props.resetForm({ values: { ...iniValues, ...dataE, ...dataR, isFree } });
  }, [dataE, dataR]);
  // ...
  return (
    <Page title="Event Registration | Evey">
      <RegSection
        title="Define your event visibility"
        helper="Please define the requested action for this visibility type"
        entries={{
          name: "access",
          options: EVENT_VISIBILITIES,
        }}
      />

      <RegSection
        title="Manage registration fields"
        helper="Please define the requested action for this visibility type"
        entries={{
          name: "regLabel",
          options: REGISTRATION_METHODS,
        }}
      />

      <EventActions
        save={{
          isActive: true,
          label: "Save & continue",
          isLoading,
          handler: onSaveData,
        }}
        cancel={{
          isActive: true,
          label: "Back",
          handler: onBackHandler,
        }}
      />
    </Page>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(EventRegistration);
