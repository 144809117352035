import { Grid } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withFormik } from "formik";
import React, { useEffect } from "react";

import {
  EntryAutocomplete,
  EntryDate,
  EntrySelect,
  EntrySlug,
  EntrySwitch,
  EntryText,
  EntryTime,
} from "../../../components/Entry";
import EventActions from "../../../components/EventActions";
import EventForm from "../../../components/EventForm";
// ...
import Page from "../../../components/Page";
import useEvent from "../../../hooks/useEvent";
import RegEntries from "../components/RegEntries";
import {
  EVENT_CATEGORIES,
  EVENT_FEES,
  EVENT_TAGS,
  EVENT_TYPES,
  valSchemaInfo as validationSchema,
} from "../entries";
const iniValues = validationSchema.cast();

const EventTypeStyle = styled("div")(() => ({
  position: "relative",
  margin: "10px 0 20px 0",
  "& > label": {
    display: "block",
    color: "#637381",
    fontFamily: "Poppins,sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "500",
    margin: "0 0 12px 0",
  },
}));

const EventInfo = ({ setCurrTab, ...props }) => {
  // const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { isLoading, data, onUpdate } = useEvent();
  // ...
  const onSaveData = async () => {
    if (isLoading) return;
    // ...
    const res = await onUpdate(props.values);
    if (res.error) return;
    setCurrTab((s) => s + 1);
  };
  // ...
  useEffect(() => {
    if (isLoading) return;
    const isFree = data?.isFree ? "free" : "paid";
    props.resetForm({ values: { ...iniValues, ...data, isFree } });
  }, [data]);
  // ...
  return (
    <Page title="Event Setup | Evey">
      <EventForm>
        <EntryText
          required
          label="Event name"
          name="title"
          placeholder="Event name"
        />
        <EntrySlug
          required
          label="Event slug"
          name="slug"
          placeholder="Event slug"
        />
        <EntryDate
          required
          label="Event start date"
          name="startDate"
          xs={12}
          md={3}
        />
        <EntryTime
          required
          label="Event start time"
          name="startDate"
          xs={12}
          md={3}
        />
        <EntryDate
          required
          label="Event end date"
          name="endDate"
          xs={12}
          md={3}
        />
        <EntryTime
          required
          label="Event end time"
          name="endDate"
          xs={12}
          md={3}
        />
        <EntryText
          required
          label="Short description"
          name="shortDescription"
          placeholder="Short description"
          multiline
          minRows={4}
          maxRows={4}
        />
        <EntryText
          label="Long description"
          name="description"
          placeholder="Long Description"
          multiline
          minRows={4}
          maxRows={4}
        />

        <EntryText
          required
          label="Event location"
          name="address"
          placeholder="Event location"
          xs={12}
          md={6}
        />
        <Grid item xs={0} md={6} />

        <Grid item xs={12} md={6}>
          <EventTypeStyle>
            <label>Event type</label>
            <RegEntries
              name="type"
              value={props.values?.type}
              handleChange={props.handleChange}
              handleBlur={props.handleBlur}
              options={EVENT_TYPES}
            />
          </EventTypeStyle>
        </Grid>
        <Grid item xs={12} md={6}>
          <EventTypeStyle>
            <label>Event fees</label>
            <RegEntries
              name="isFree"
              value={props.values?.isFree}
              handleChange={props.handleChange}
              handleBlur={props.handleBlur}
              options={EVENT_FEES}
            />
          </EventTypeStyle>
        </Grid>

        <EntrySelect
          label="Event Category"
          name="category"
          options={EVENT_CATEGORIES}
        />
        <EntryAutocomplete
          freeSolo
          name="tags"
          helper="Press ENTER to add tags"
          options={EVENT_TAGS}
        />
        <EntrySwitch
          name="requireVisio"
          label="This event requires a visio conference"
        />
      </EventForm>

      <EventActions
        save={{
          isActive: true,
          label: "Save & continue",
          isLoading,
          handler: onSaveData,
        }}
      />
    </Page>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(EventInfo);
