import EventTicketDesign from "./tabs/Design";
import EventTicketDetails from "./tabs/Details";
import EventTicketPreview from "./tabs/Preview";

export const TABS_LIST = [
  {
    step: 0,
    label: "Category & content",
    Node: EventTicketDetails,
  },
  {
    step: 1,
    label: "Design & layout",
    Node: EventTicketDesign,
  },
  {
    step: 2,
    label: "Review & publish",
    Node: EventTicketPreview,
  },
];
