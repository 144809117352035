import styled from "styled-components";

export const FileUploadContainer = styled.section`
  position: relative;
  height: 56px;
  margin: 0;
  border: 1px solid rgba(145, 158, 171, 0.32);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
`;

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: 1px;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`;

export const DragDropText = styled.p`
  font-family: "Be Vietnam", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #919eab;
`;

export const UploadFileBtn = styled.button`
  box-sizing: border-box;
  appearance: none;
  background-color: #00ab55;
  border: 2px solid #00ab55;
  cursor: pointer;
  font-size: 15px;
  line-height: 1;
  text-align: center;
  font-weight: 600;
  border-radius: 8px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 250ms ease-in-out;
  font-family: "Montserrat", sans-serif;
  width: 135px;
  height: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: #00ab55;
    z-index: -1;
    transition: width 250ms ease-in-out;
  }

  i {
    font-size: 22px;
    margin-right: 5px;
    border-right: 2px solid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
`;

export const FilePreviewContainer = styled.article`
  margin-bottom: 35px;
  span {
    font-size: 14px;
  }
`;

export const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

export const FileMetaData = styled.div`
  display: ${(props) => (props.isImageFile ? "none" : "flex")};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(5, 5, 5, 0.55);

  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }
`;

export const RemoveFileIcon = styled.i`
  cursor: pointer;
  &:hover {
    transform: scale(1.3);
  }
`;

export const PreviewContainer = styled.section`
  padding-bottom: 0.5rem;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  &:hover {
    opacity: 0.55;

    ${FileMetaData} {
      display: flex;
      flex-direction: column;
    }
  }

  & > div:first-of-type {
    height: 100%;
    position: relative;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    padding: 0 0 0.4em;
  }
`;

export const ImagePreview = styled.img`
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;
